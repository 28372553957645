import React from 'react'
import { useTranslation } from 'react-i18next'

const EmptyList = ({
    message,
}) => {
    
    const { t } = useTranslation()

    return (
        <div className='empty-list'>
            <h3>{ message ? message : t('no_content') }</h3>
        </div>
    )
}

export default EmptyList