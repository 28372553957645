import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GetAuthorDistributions, GetDistributionTotal, GetReport } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import { ReportTypes } from '../../util/const'
import { formatDate } from '../../util/format'
import { capitalizeName } from '../../util/string'
import DistributionFilters from '../../components/DistributionFilters'
import Total from '../../components/Total'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'creation',
        label: 'Teos',
    },
    {
        value: 'views',
        label: 'Vaatamised',
    },
    {
        value: 'role',
        label: 'Roll',
    },
    {
        value: 'total',
        label: 'Bruto summa',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const AuthorDistributionScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id, name, authorName } = useParams()
    const tableRef = useRef()

    const [filters, setFilters] = useState(initialFilterState)
    const [extraFilters, setExtraFilters] = useState()
    const [total, setTotal] = useState(null)

    const { refetch: getTotal } = useQuery(GetDistributionTotal, {
        fetchPolicy: 'no-cache',
        skip: true,
    })

    const { data: reportData } = useQuery(GetReport, { variables: { id: parseInt(id) } })

    useEffect(() => {
        (async () => {
            if (reportData?.getReport?.type && reportData?.getReport?.year) {
                const res = await getTotal({
                    year: parseInt(reportData.getReport.year),
                    type: reportData.getReport.type,
                    distribution: name,
                    author: authorName,
                })

                if (res?.data?.getDistributionTotal) setTotal(res?.data?.getDistributionTotal)
            }
        })()
    }, [reportData])

    const fieldConditions = (row, field) => {
        console.log(row)
        switch (field) {
            case 'total':
                return `${(parseFloat(row[field])).toFixed(2)} €`
            case 'createdAt':
                return formatDate(row[field])
            case 'creation':
                return `${capitalizeName(row.creation?.title)} ${row.creation?.year} ${row.creation?.episode ? `- ${row.creation.episode}` : ''}`
            case 'views':
                return row[field]
            case 'authorName':
                return capitalizeName(row[field])
            case 'role':
                return t(row.role?.name)
            default:
                return row[field]
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const getRowClass = (row) => {
        return row?.user?.id || row?.namedUser?.id ? 'is-user' : ''
    }

    return (
        <div className='author-distributions'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ `${authorName}` }</h1>
                    {
                        reportData?.getReport ?
                        <h4>{ `${name} - ${reportData?.getReport?.type} ${reportData?.getReport?.year}` }</h4>
                        :
                        <></>
                    }
                </div>
                <div className='below-title'>
                    <DistributionFilters
                        onFilterChange={handleFilterChange}
                    />
                    <Total
                        total={total}
                    />
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetAuthorDistributions}
                        fieldConditions={fieldConditions}
                        queryKey={'getAuthorDistributions'}
                        hideInputs={true}
                        initialOrderBy={'creation'}
                        queryOptions={{
                            fetchPolicy: 'no-cache',
                            variables: {
                                reportId: parseInt(id),
                                distribution: name,
                                authorName,
                            },
                        }}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        rowClass={getRowClass}
                    />
                </div>
            </div>
        </div>
    )
}

export default AuthorDistributionScreen