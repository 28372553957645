import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from './icons/search'
import Input from './Input'

const initialState = {
    query: null,
}

const PayoutFilters = forwardRef(({
    onFilterChange,
    allow,
}, ref) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    useImperativeHandle(ref, () => ({
        refresh () {
        },
    }))

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters filters-foreign'>
            {
                allow?.search ?
                <div className='filters-search'>
                    <Input
                        placeholder={ t('payouts.search') }
                        onChange={(e) => setField('query', e.target.value)}
                        value={filters.query}
                        icon={<SearchIcon />}
                    />
                </div>
                :
                <></>
            }
        </div>
    )
})

export default PayoutFilters