import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import { useMutation, useQuery } from '@apollo/client'
import { GetUser, SearchUsers } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { AddHeir, EditUser, RemoveUser } from '../../graphql/mutations'
import Button from '../../components/Button'
import Textarea from '../../components/Textarea'
import HeirItem from '../../components/HeirItem'
import ConfirmModal from '../../components/ConfirmModal'
import DeleteIcon from '../../components/icons/delete'
import CheckmarkIcon from '../../components/icons/checkmark'
import { UserRoles } from '../../util/const'

const initialState = {
    name: null,
    pid: null,
    ipi: null,
    email: null,
    phone: null,
    address: null,
    iban: null,
    pseudonym: null,
    dead: null,
    role: null,
}

const initialHeir = {
    percentage: null,
    heirId: null,
    authorId: null,
}

const userRoles = [
    {
        label: 'Administraator',
        value: UserRoles.Admin,
    },
    {
        label: 'Klient',
        value: UserRoles.Client,
    },
    {
        label: 'Liige',
        value: UserRoles.Member,
    },
]

const UserScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [deleteModal, setDeleteModal] = useState(false)
    const [saving, setSaving] = useState(false)

    const [edit] = useMutation(EditUser)
    const [addHeir] = useMutation(AddHeir)
    const [removeUser] = useMutation(RemoveUser)

    const [item, setItem] = useState(initialState)
    const [newHeir, setNewHeir] = useState({
        ...initialHeir,
        authorId: parseInt(id),
    })

    const { refetch: searchUsers } = useQuery(SearchUsers, {
        skip: true,
    })

    const { refetch, loading } = useQuery(GetUser, {
        fetchPolicy: 'no-cache',
        variables: {
            id: parseInt(id),
        },
        onCompleted: (data) => {
            if (!data?.getUser) return
            setItem(data.getUser)
        },
    })

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })
    }

    const loadUsers = async (input) => {
        if (input.length < 2) return
        const res = await searchUsers({
            input,
        })
        return res?.data?.searchUsers
    }

    const saveUser = async () => {
        setSaving(true)

        try {
            let data = {
                ...item,
            }

            delete data.id
            delete data.__typename
            delete data.heirs
            delete data.authors

            await edit({
                variables: {
                    id: parseInt(id),
                    data,
                },
            })
        } catch (err) {
            console.log('saveUser: ', err)
        } finally {
            setSaving(false)
        }
    }

    const createInheritance = async () => {
        try {
            await addHeir({
                variables: {
                    data: {
                        ...newHeir,
                        percentage: parseFloat(newHeir.percentage),
                    },
                },
            })
            refetch()
        } catch (err) {
            console.log('createInheritance: ', err)
        }
    }

    const handleDelete = async () => {
        try {
            const res = await removeUser({
                variables: {
                    id: parseInt(id),
                },
            })

            if (res?.data?.removeUser === 'SUCCESS') {
                navigate('/users')
            }
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='user'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ item.name }</h1>
                    <Button
                        icon={<DeleteIcon />}
                        label={ t('user.delete') }
                        onClick={() => setDeleteModal(true)}
                    />
                    <Button
                        icon={<CheckmarkIcon />}
                        label={ t('user.save') }
                        onClick={saveUser}
                        loading={saving}
                        disabled={saving}
                    />
                </div>
                <div className='user-content'>
                    <div className='user-info'>
                        <Input
                            label={ t('user.name') }
                            value={item.name}
                            onChange={(e) => setField('name', e.target.value)}
                        />
                        <Input
                            label={ t('user.pid') }
                            value={item.pid}
                            onChange={(e) => setField('pid', e.target.value)}
                        />
                        <Input
                            label={ t('user.ipiBase') }
                            value={item.ipiBase}
                            onChange={(e) => setField('ipiBase', e.target.value)}
                        />
                        <Input
                            label={ t('user.ipi') }
                            value={item.ipi}
                            onChange={(e) => setField('ipi', e.target.value)}
                        />
                        <Input
                            label={ t('user.email') }
                            value={item.email}
                            onChange={(e) => setField('email', e.target.value)}
                        />
                        <Input
                            label={ t('user.phone') }
                            value={item.phone}
                            onChange={(e) => setField('phone', e.target.value)}
                        />
                        <Input
                            label={ t('user.address') }
                            value={item.address}
                            onChange={(e) => setField('address', e.target.value)}
                        />
                        <Input
                            label={ t('user.iban') }
                            value={item.iban}
                            onChange={(e) => setField('iban', e.target.value)}
                        />
                        <SelectInput
                            label={ t('users.role') }
                            options={userRoles}
                            value={userRoles.find(x => x.value === item.role)}
                            getOptionLabel={(option) => t(option.label)}
                            onChange={(option) => setField('role', option.value)}
                        />
                        <Checkbox
                            label={`${t('user.dead')}`}
                            value={item.dead}
                            onChange={(e) => setField('dead', e.target.checked)}
                        />
                    </div>

                    <div className='user-alt'>
                        <h3>{ t('user.alternatives') }</h3>
                        <div className='user-alt--item'>
                            <Input
                                label={ t('user.altName') }
                                value={item.altName}
                                onChange={(e) => setField('altName', e.target.value)}
                            />
                            <Input
                                label={ t('user.ipi') }
                                value={item.altIpi}
                                onChange={(e) => setField('altIpi', e.target.value)}
                            />
                        </div>
                        <div className='user-alt--item'>
                            <Input
                                label={ t('user.pseudonym') }
                                value={item.pseudonym}
                                onChange={(e) => setField('pseudonym', e.target.value)}
                            />
                            <Input
                                label={ t('user.ipi') }
                                value={item.pseudoIpi}
                                onChange={(e) => setField('pseudoIpi', e.target.value)}
                            />
                        </div>
                        <h3>{ t('user.comment') }</h3>
                        <Textarea
                            value={item.comment}
                            onChange={(e) => setField('comment', e.target.value)}
                        />
                    </div>
                
                    <div className='user-heirs'>
                        <h3>{ t('user.heirs') }</h3>
                        <div className='user-heirs--list'>
                            {
                                item.heirs && item.heirs.length > 0 ?
                                item.heirs.map((heirItem) =>
                                    <HeirItem
                                        key={heirItem.id}
                                        data={heirItem}
                                        onRemove={refetch}
                                    />
                                )
                                :
                                <p>{ t('user.no_heirs') }</p>
                            }
                        </div>
                        <div className='user-heirs--add'>
                            <h4>{ t('user.heir_add') }</h4>
                            <div className='user-heirs--search'>
                                <SelectInput
                                    placeholder={t('users.search_user')}
                                    load={loadUsers}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => setNewHeir({ ...newHeir, heirId: parseInt(option.id) })}
                                    loadingMessage={() => t('users.loading_users')}
                                    noOptionsMessage={() => t('users.type_to_search')}
                                />
                            </div>
                            <Input
                                className={'user-heirs--add-percentage'}
                                label={t('user.heir_percentage')}
                                value={newHeir.percentage}
                                onChange={(e) => setNewHeir({ ...newHeir, percentage: e.target.value })}
                                suffix={'%'}
                                type={'number'}
                            />
                            <Button
                                label={t('user.heir_add')}
                                onClick={createInheritance}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('user.delete')}
                close={() => setDeleteModal(false)}
                onConfirm={handleDelete}
                title={t('user.confirm_delete', { name: item.name })}
            />
        </div>
    )
}

export default UserScreen