import React from 'react'
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import { useAuth } from './providers/Auth'
import BudgetScreen from './screens/Budget'
import CreationScreen from './screens/Creation'
import CreationsScreen from './screens/Creations'
import LoginScreen from './screens/Login'
import ProfileScreen from './screens/Profile'
import ReportsScreen from './screens/Reports'
import ReportSettingsScreen from './screens/ReportSettings'
import SocietiesScreen from './screens/Societies'
import SocietyScreen from './screens/Society'
import UserScreen from './screens/User'
import UsersScreen from './screens/Users'
import ViewsScreen from './screens/Views'
import UnrecognizedScreen from './screens/Unrecognized'
import DistributionScreen from './screens/Distribution'
import AuthorDistributionScreen from './screens/AuthorDistribution'
import AuthorsReportsScreen from './screens/AuthorsReports'
import AuthorReportsScreen from './screens/AuthorReports'
import UserReportsScreen from './screens/UserReports'
import BonusesScreen from './screens/Bonuses'
import { UserRoles } from './util/const'
import SelectRoleScreen from './screens/SelectRole'
import UserDistributionScreen from './screens/UserDistribution'
import ForeignScreen from './screens/Foreign'
import UserTabsScreen from './screens/UserTabs'
import UnrecognizedCreationScreen from './screens/UnrecognizedCreation'
import TasksScreen from './screens/Tasks'
import PayoutsScreen from './screens/Payouts'
import UserCreationsScreen from './screens/UserCreations'
import UserCreationScreen from './screens/UserCreation'
import FixesScreen from './screens/Fixes'
import BalancesScreen from './screens/Balances'

const Routing = () => {

  const { user, loaded } = useAuth()
  const { pathname } = useLocation()

  if (!loaded) return <></>

  return (
      user ?
      (
          <div>
              <AppHeader />
              <div className={`app-content${pathname ? ` ${pathname.split('/')[1]}` : ''}`}>
                  <Routes>
                    <Route path='/' element={user.role === UserRoles.Admin ? <CreationsScreen /> : <SelectRoleScreen />} />
                    <Route path='/profile' element={<ProfileScreen />} />
                    <Route path='/users' element={<UsersScreen />} />
                    <Route path='/user/:id' element={<UserScreen />} />
                    <Route path='/creations' element={<CreationsScreen />} />
                    <Route path='/creation/:id' element={<CreationScreen />} />
                    <Route path='/budget' element={<BudgetScreen />} />
                    <Route path='/societies' element={<SocietiesScreen />} />
                    <Route path='/society/:id' element={<SocietyScreen />} />
                    <Route path='/views' element={<ViewsScreen />} />
                    <Route path='/unrecognized' element={<UnrecognizedScreen />} />
                    <Route path='/unrecognized/:id' element={<UnrecognizedCreationScreen />} />
                    <Route path='/reports' element={<ReportsScreen />} />
                    <Route path='/report-settings' element={<ReportSettingsScreen />} />
                    <Route path='/authors-reports' element={<AuthorsReportsScreen />} />
                    <Route path='/authors-reports/:reportId/:authorName' element={<AuthorReportsScreen />} />
                    <Route path='/distribution/:id/:name' element={<DistributionScreen />} />
                    <Route path='/distribution/:id/:name/:authorName' element={<AuthorDistributionScreen />} />
                    <Route path='/bonuses' element={<BonusesScreen />} />
                    <Route path='/foreign' element={<ForeignScreen />} />
                    <Route path='/payouts' element={<PayoutsScreen />} />
                    <Route path='/fixes' element={<FixesScreen />} />
                    <Route path='/balances' element={<BalancesScreen />} />
                    <Route path='/user-tabs/:role' element={<UserTabsScreen />} />
                    <Route path='/user-tabs/:role/:authorId' element={<UserTabsScreen />} />
                    <Route path='/user-reports/:role' element={<UserReportsScreen />} />
                    <Route path='/user-reports/:role/:authorId' element={<UserReportsScreen />} />
                    <Route path='/user-distribution/:id/:name' element={<UserDistributionScreen />} />
                    <Route path='/user-distribution/:id/:name/:authorId' element={<UserDistributionScreen />} />
                    <Route path='/user-creations' element={<UserCreationsScreen />} />
                    <Route path='/user-creation/:id' element={<UserCreationScreen />} />
                    {/* <Route path='/tasks' element={<TasksScreen />} /> */}
                  </Routes>
              </div>
              <AppFooter />
          </div>
      )
      :
      (
          <Routes>
            <Route
                path='*'
                element={<LoginScreen />}
            />
          </Routes>
      )
  )
}

export default Routing