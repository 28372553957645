import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image, Link } from '@react-pdf/renderer'
import RobotoRegular from '../assets/Roboto-Regular.ttf'
import RobotoBold from '../assets/Roboto-Bold.ttf'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../util/format'

Font.register(
  {
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular,
        fontWeight: 400,
      },
      {
        src: RobotoBold,
        fontWeight: 700,
      },
    ],
  },
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
  },
  wrapper: {
    borderWidth: 1,
    borderColor: '#000',
    width: 400,
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  infoRow: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  tableRowItem: {
    paddingVertical: 10,
  },
  tableHeadLabel: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 7,
    textTransform: 'uppercase',
    color: '#010c3a',
    letterSpacing: 0.2,
  },
  tableColText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
  strong: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  col1: {
    width: '10%',
    paddingRight: 3,
  },
  col2: {
    width: '12.5%',
    paddingRight: 3,
  },
  col3: {
    width: '12.5%',
    paddingRight: 3,
  },
  col4: {
    width: '15%',
    paddingRight: 3,
  },
  col5: {
    width: '12.5%',
    paddingRight: 3,
  },
  col6: {
    width: '12.5%',
  },
  col7: {
    width: '12.5%',
  },
  col8: {
    width: '12.5%',
    alignItems: 'flex-end',
  },
  colDouble: {
    width: '25%',
  },
  headerCol: {
    backgroundColor: '#f9f9fb',
    paddingVertical: 8,
    paddingHorizontal: 4,
    marginHorizontal: 1,
    justifyContent: 'center',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    width: 70,
  },
  value: {
    fontFamily: 'Roboto',
    marginBottom: 4,
  },
  info: {
    fontSize: 10,
  },
  eauLogo: {
    width: 77,
    height: 38,
    marginRight: 30,
  },
  eaalLogo: {
    width: 100,
    height: 29,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 80,
  },
  linkStyle: {
    color: '#010c3a',
  },
})

const IncomeTaxDocument = ({
    data,
    user,
    year,
}) => {

  const { t } = useTranslation()

  if (!data) return <Document>
    <Page size="A4" style={styles.page} orientation={'landscape'}></Page>
  </Document>

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'landscape'}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View>
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                }}
            >
                <Image
                    style={styles.eauLogo}
                    source={require('../assets/logo-blue.png')}
                />
                <Image
                    style={styles.eaalLogo}
                    source={require('../assets/eaal-logo-blue.png')}
                />
            </View>
            <Text style={{ marginBottom: 2 }}>Eesti Autorite Ühing</Text>
            <Text style={{ marginBottom: 2 }}>Lille 13, 10614 Tallinn</Text>
            <Text>Registrikood 80004182</Text>
          </View>
        </View>
        <View
          style={[
            {
              fontFamily: 'Roboto',
              fontWeight: 700,
              marginBottom: 20,
              marginTop: 20,
              color: '#010C3A',
            },
          ]}
        >
          <Text style={{ fontSize: 14 }}>{ `Tulumaksuõiend ${data.year}. a` }</Text>
          <View
            style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 20,
            }}
          >
            <Text>{ `Makse saaja: ${data.name}` }</Text>
            <Text>{ `Kuupäev: ${formatDate(new Date())}` }</Text>
          </View>
        </View>

        <View>
          <View style={[styles.tableRow]}>
            <View style={[styles.col1, styles.headerCol, { borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }]}>
              <Text style={styles.tableHeadLabel}>{ t('Riik') }</Text>
            </View>
            <View style={[styles.colDouble, styles.headerCol]}>
                <Text style={[styles.tableHeadLabel, { textAlign: 'center', marginBottom: 8 }]}>{ t('Väljamakse tegija') }</Text>
                <View
                    style={{
                        flexDirection: 'row',
                    }}
                >
                    <View style={[styles.col2, { flex: 1 }]}>
                        <Text style={[styles.tableHeadLabel, { textAlign: 'center' }]}>{ t('Reg. kood') }</Text>
                    </View>
                    <View style={[styles.col3, { flex: 1 }]}>
                        <Text style={[styles.tableHeadLabel, { textAlign: 'center' }]}>{ t('Nimi') }</Text>
                    </View>
                </View>
            </View>
            <View style={[styles.col4, styles.headerCol]}>
              <Text style={styles.tableHeadLabel}>{ t('Tulu liik') }</Text>
            </View>
            <View style={[styles.col5, styles.headerCol]}>
              <Text style={styles.tableHeadLabel}>{ t('Rahaühik') }</Text>
            </View>
            <View style={[styles.col6, styles.headerCol]}>
              <Text style={styles.tableHeadLabel}>{ t('Tulu summa') }</Text>
            </View>
            <View style={[styles.col7, styles.headerCol]}>
              <Text style={styles.tableHeadLabel}>{ t('Kinnipeetud\n tulumaks') }</Text>
            </View>
            <View style={[styles.col8, styles.headerCol, { borderTopRightRadius: 4, borderBottomRightRadius: 4 }]}>
              <Text style={[styles.tableHeadLabel, { textAlign: 'right' }]}>{ t('Väljamakse ja\n tulumaksu\n kinnipidamise\n kuupäev') }</Text>
            </View>
          </View>
          {
            data && data.items.map((row, index) =>
            <View key={`item-${index}`} style={[styles.tableRow, styles.tableRowItem]}>
              <View style={[styles.col1, { paddingLeft: 3 }]}>
                <Text style={styles.tableColText}>{ row.country }</Text>
              </View>
              <View style={styles.col2}>
                <Text style={[styles.tableColText, { textAlign: 'center' }]}>{ row.regno }</Text>
              </View>
              <View style={styles.col3}>
                <Text style={[styles.tableColText, { textAlign: 'center' }]}>{ row.society }</Text>
              </View>
              <View style={styles.col4}>
                <Text style={styles.tableColText}>{ row.type }</Text>
              </View>
              <View style={styles.col5}>
                <Text style={styles.tableColText}>{ row.currency }</Text>
              </View>
              <View style={styles.col6}>
                <Text style={styles.tableColText}>{ (row.total).toFixed(2) }</Text>
              </View>
              <View style={styles.col7}>
                <Text style={styles.tableColText}>{ (row.tax).toFixed(2) }</Text>
              </View>
              <View style={[styles.col8, { paddingRight: 3 }]}>
                <Text style={styles.tableColText}>{ formatDate(row.date) }</Text>
              </View>
            </View>
            )
          }
        </View>
        <View
            style={{
                fontSize: 10,
                flex: 1,
                marginTop: 80,
            }}
        >
          <Text style={{ fontWeight: '700', marginBottom: 2 }}>{ `Märt Truman` }</Text>
          <Text style={{ marginBottom: 2 }}>{ `Audiovisuaalteoste valdkonna projektijuht` }</Text>
          <Text style={{ marginBottom: 2 }}>{ `Eesti Autorite Ühing` }</Text>
          <Text style={{ marginBottom: 2 }}><Link style={styles.linkStyle} src='mailto:mart.truman@eau.org'>{ `mart.truman@eau.org` }</Link></Text>
          <Text><Link style={styles.linkStyle} src='tel:+3726684372'>{ `+372 668 43 72` }</Link></Text>
        </View>
      </Page>
    </Document>
  )
}

export default IncomeTaxDocument