import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { ImportViews } from '../graphql/mutations'
import SelectInput from './SelectInput'
import { ReportTypes } from '../util/const'
import FileUpload from './FileUpload'
import { Countries } from '../util/countries'

const columns = [
    {
        label: 'A',
        value: 1,
    },
    {
        label: 'B',
        value: 2,
    },
    {
        label: 'C',
        value: 3,
    },
    {
        label: 'D',
        value: 4,
    },
    {
        label: 'E',
        value: 5,
    },
    {
        label: 'F',
        value: 6,
    },
    {
        label: 'G',
        value: 7,
    },
    {
        label: 'H',
        value: 8,
    },
    {
        label: 'I',
        value: 9,
    },
    {
        label: 'J',
        value: 10,
    },
    {
        label: 'K',
        value: 11,
    },
    {
        label: 'L',
        value: 12,
    },
    {
        label: 'M',
        value: 13,
    },
    {
        label: 'N',
        value: 14,
    },
    {
        label: 'O',
        value: 15,
    },
]

const types = [
    {
        label: 'TV',
        value: ReportTypes.TV,
    },
    {
        label: 'VOD',
        value: ReportTypes.VOD,
    },
]

const initialState = {
    year: '',
    type: '',
    distribution: '',
    startRow: '',
    country: '',
}

const initialMap = {
    title: '',
    year: '',
    originalTitle: '',
    season: '',
    episode: '',
    episodeTitle: '',
    count: null,
    country: '',
    producer: '',
}

const initialErrorsState = {
    titleCol: '',
    yearCol: '',
    originalTitleCol: '',
    episodeCol: '',
    countCol: '',
    seasonCol: '',
    file: '',
    year: '',
    type: '',
    country: '',
    distribution: '',
    startRow: '',
    generic: '',
}

const CreateViewsModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [columnMap, setColumnMap] = useState(initialMap)
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState(initialErrorsState)
    const [loading, setLoading] = useState(false)
    const [importViews] = useMutation(ImportViews)

    const handleAdd = async () => {
        if (hasInputErrors()) return

        setLoading(true)

        try {
            const res = await importViews({
                variables: {
                    file,
                    options: {
                        ...item,
                        year: parseInt(item.year),
                        startRow: parseInt(item.startRow),
                        country: item.country,
                        map: {
                            ...columnMap,
                            count: columnMap.count ? parseInt(columnMap.count) : null,
                            season: columnMap.season ? parseInt(columnMap.season) : null,
                            episode: columnMap.episode ? parseInt(columnMap.episode) : null,
                            producer: columnMap.producer ? parseInt(columnMap.producer) : null,
                            country: columnMap.country ? parseInt(columnMap.country) : null,
                        },
                    },
                },
            })

            if (!res?.data?.importViews) {
                setErrors({
                    ...errors,
                    generic: t('views.import_error'),
                })
                return
            }

            if (onSuccess) onSuccess(res.data.importViews)
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('views.import_error'),
            })
            console.log('handleAdd', err)
        } finally {
            setLoading(false)
        }
    }

    const handleFileChange = async (file) => {
        setFile(file)
    }

    const handleClose = () => {
        if (close) close()
        setFile(null)
        setErrors(initialErrorsState)
        setItem(initialState)
        setColumnMap(initialMap)
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        console.log(columnMap)

        if (!item.year) {
            hasErrors = true
            errorState.year = t('views.fill_field')
        }

        if (!item.distribution) {
            hasErrors = true
            errorState.distribution = t('views.fill_field')
        }

        if (!item.type) {
            hasErrors = true
            errorState.type = t('views.fill_field')
        }

        if (!item.country) {
            hasErrors = true
            errorState.country = t('views.fill_field')
        }

        if (!file) {
            hasErrors = true
            errorState.generic = t('views.file_not_found')
        }

        if (!columnMap.title) {
            hasErrors = true
            errorState.titleCol = t('views.fill_field')
        }

        if (!columnMap.originalTitle) {
            hasErrors = true
            errorState.originalTitleCol = t('views.fill_field')
        }

        if (!columnMap.year) {
            hasErrors = true
            errorState.yearCol = t('views.fill_field')
        }

        if (!item.startRow) {
            hasErrors = true
            errorState.startRow = t('views.fill_field')
        }

        if (!columnMap.episode) {
            hasErrors = true
            errorState.episodeCol = t('views.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    return (
        <Modal
            className={'modal-views'}
            show={show}
            close={handleClose}
            title={ t('views.add_new') }
        >
            <Input
                label={ t('views.distribution') }
                value={item.distribution}
                onChange={(e) => setItem({ ...item, distribution: e.target.value })}
                error={errors.distribution}
            />

            <SelectInput
                label={ t('reports.country') }
                options={Countries.list}
                value={Countries.list.find(x => x.value === item.country)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setItem({ ...item, country: option.value })}
                error={errors.country}
            />

            <Input
                label={ t('views.year') }
                value={item.year}
                type={'number'}
                onChange={(e) => setItem({ ...item, year: e.target.value })}
                error={errors.year}
            />
            <SelectInput
                label={ t('views.type') }
                options={types}
                value={types.find(x => x.value === item.type)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setItem({ ...item, type: option.value })}
                error={errors.type}
            />

            <FileUpload
                onChange={handleFileChange}
            />

            <h5>{ t('views.map') }</h5>
            <div className='views-map'>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.creation_title') }
                        options={columns}
                        value={columns.find(x => x.value === columnMap.title)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, title: option.value })}
                        error={errors.titleCol}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.originalTitle') }
                        options={columns}
                        value={columns.find(x => x.value === columnMap.originalTitle)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, originalTitle: option.value })}
                        error={errors.originalTitleCol}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.creation_year') }
                        options={columns}
                        value={columns.find(x => x.value === columnMap.year)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, year: option.value })}
                        error={errors.yearCol}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        isClearable={true}
                        label={ `${t('views.view_count')} (${t('views.optional')})` }
                        options={columns}
                        value={columns.find(x => x.value === columnMap.count)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, count: option ? option.value : null })}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.season') }
                        isClearable={true}
                        options={columns}
                        value={columns.find(x => x.value === columnMap.season)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, season: option ? option.value : null })}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.episode') }
                        isClearable={true}
                        options={columns}
                        value={columns.find(x => x.value === columnMap.episode)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, episode: option ? option.value : null })}
                        error={errors.episodeCol}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.episode_title') }
                        isClearable={true}
                        options={columns}
                        value={columns.find(x => x.value === columnMap.episodeTitle)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, episodeTitle: option ? option.value : null })}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.country') }
                        isClearable={true}
                        options={columns}
                        value={columns.find(x => x.value === columnMap.country)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, country: option ? option.value : null })}
                    />
                </div>
                <div className='views-map--item'>
                    <SelectInput
                        label={ t('views.producer') }
                        isClearable={true}
                        options={columns}
                        value={columns.find(x => x.value === columnMap.producer)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setColumnMap({ ...columnMap, producer: option ? option.value : null })}
                    />
                </div>
            </div>
            <Input
                label={ t('views.start_row') }
                value={item.startRow}
                type={'number'}
                onChange={(e) => setItem({ ...item, startRow: e.target.value })}
                error={errors.startRow}
            />

            {
                errors && errors.generic ?
                <div className='error-message'>
                    <span>{ errors.generic }</span>
                </div>
                :
                <></>
            }

            <Button
                label={ t('views.add_new') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

export default CreateViewsModal