import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetProfile, GetUserBonuses, GetUserBonusTotal, GetViewFilters } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import { ReportTypes } from '../../util/const'
import { formatDate, formatPrice } from '../../util/format'
import { useAuth } from '../../providers/Auth'
import BonusFilters from '../../components/BonusFilters'
import Total from '../../components/Total'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'label',
        label: 'Nimetus',
    },
    {
        value: 'sum',
        label: 'Bruto summa',
    },
    {
        value: 'description',
        label: 'Selgitus',
    },
    {
        value: 'date',
        label: 'Kuupäev',
    },
]

const UserBonusesScreen = ({
    role,
    authorId,
}) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const filtersRef = useRef()

    const [filters, setFilters] = useState(initialFilterState)
    const [extraFilters, setExtraFilters] = useState()
    const [currentAuthor, setCurrentAuthor] = useState(null)
    const [total, setTotal] = useState(null)

    const { refetch: getTotal } = useQuery(GetUserBonusTotal, {
        skip: true,
    })

    useEffect(() => {
        (async () => {
            const res = await getTotal({
                year: parseInt(extraFilters?.year),
                type: extraFilters?.type,
                ...(role !== 'author' && authorId && {
                    authorId: parseInt(authorId),
                }),
            })

            if (res?.data?.getUserBonusTotal) setTotal(res?.data?.getUserBonusTotal)
        })()
    }, [extraFilters])

    useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile?.authors) return
            const heir = data.getProfile.authors.find(x => x.author.id === parseInt(authorId))
            if (heir) setCurrentAuthor(heir)
        },
    })

    useQuery(GetViewFilters, {
        onCompleted: (data) => {
            if (data?.getViewFilters) {
                let yearList = []
                let distList = []
                if (data.getViewFilters.years) {
                    yearList = data.getViewFilters.years.map(x => ({
                        value: x,
                        label: x,
                    }))
                }

                if (data.getViewFilters.distributions) {
                    distList = data.getViewFilters.distributions.map(x => ({
                        value: x,
                        label: x,
                    }))
                }

                setFilters({
                    ...filters,
                    distributions: distList,
                    years: yearList,
                })
            }
        },
    })

    const fieldConditions = (row, field) => {
        if (field === 'date') return formatDate(row[field])
        if (field === 'sum') {
            if (authorId) {
                if (!currentAuthor) return ''
                return `${formatPrice(parseFloat(row[field]) * (currentAuthor.percentage / 100))} €`
            }
            return `${formatPrice(row[field])} €`
        }
        return row[field]
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    if (role !== 'author' && !authorId) return <></>

    return (
        <div className='user-bonuses'>
            <div className='inner'>
                <div className='below-title'>
                    <BonusFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allowed={{
                            year: true,
                            search: true,
                        }}
                    />
                    {/* <Total
                        total={total}
                        hidePayed={true}
                    /> */}
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        query={GetUserBonuses}
                        queryKey={'getUserBonuses'}
                        queryOptions={{
                            variables: {
                                authorId: authorId ? parseInt(authorId) : null,
                            },
                        }}
                        hideInputs={true}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'date'}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserBonusesScreen