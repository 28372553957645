import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GetAuthorRoles, GetCreation, GetCreationAuthor, GetUser } from '../graphql/queries'
import { useTranslation } from 'react-i18next'
import { FixType } from '../util/const'
import Button from './Button'

const Fix = ({
    id,
    data,
    type,
    user,
    onAddCreation,
}) => {
    const { t } = useTranslation()
    const [roles, setRoles] = useState([])
    const [creation, setCreation] = useState(null)
    const [author, setAuthor] = useState(null)

    useQuery(GetAuthorRoles, {
        onCompleted: (res) => {
            if (!res?.getAuthorRoles) return
            setRoles(res.getAuthorRoles)
        },
    })

    useQuery(GetCreation, {
        variables: {
            id: parseInt(data?.creationId),
        },
        onCompleted: (res) => {
            if (!res?.getCreation) return
            setCreation(res.getCreation)
        },
    })

    useQuery(GetCreationAuthor, {
        variables: {
            id: parseInt(data?.authorId),
        },
        onCompleted: (res) => {
            if (!res?.getCreationAuthor) return
            setAuthor(res.getCreationAuthor)
        },
    })

    const getAuthorRoles = () => {
        if (!data?.creationRoles || !roles) return ''

        return data.creationRoles.map((role) => {
            return t(roles.find((i) => i.id === role)?.name)
        }).join(', ')
    }

    const addCreation = () => {
        const creationData = {
            title: data?.creationTitle,
            originalTitle: data?.creationTitle,
            year: data?.creationYear,
            season: data?.creationSeason,
            episode: data?.creationEpisode,
            episodeTitle: data?.creationEpisodeTitle,
        }

        let authors = null

        if (data.creationRoles?.length) {
            authors = data.creationRoles.map((role) => ({
                authorName: user.name,
                authorRole: role,
                ipi: user.ipi,
            }))
        }
        
        if (onAddCreation) onAddCreation({
            creation: creationData,
            authors,
            fixId: id,
        })
    }

    const renderFix = () => {
        switch (type) {
            case FixType.AddCreation:
                return (
                    <div className='fix'>
                        <div className='fix-creation'>{`Teos: ${data?.creationTitle}`}</div>
                        <div className='fix-author'>{`Autor: ${user?.name}`}</div>
                        <div className='fix-year'>{`Aasta: ${data?.creationYear}`}</div>
                        {
                            data.creationSeason ?
                            <div className='fix-season'>{`Hooaeg: ${data.creationSeason}`}</div>
                            :
                            <></>
                        }
                        {
                            data.creationEpisode ?
                            <div className='fix-episode'>{`Seeria nr: ${data.creationEpisode}`}</div>
                            :
                            <></>
                        }
                        {
                            data.creationEpisodeTitle ?
                            <div className='fix-episode'>{`Seeria pealkiri: ${data.creationEpisodeTitle}`}</div>
                            :
                            <></>
                        }
                        <div className='fix-roles' style={{ marginBottom: '10px' }}>{ `Rollid: ${getAuthorRoles()}` }</div>
                        <Button
                            label={'Lisa teos'}
                            onClick={addCreation}
                        />
                    </div>
                )
            case FixType.AddAuthor:
                return (
                    <div className='fix'>
                        <div className='fix-creation'>{`Teos: ${creation?.title}`}</div>
                        <div className='fix-author'>{`Autor: ${data?.authorName}`}</div>
                        <div className='fix-roles'>{ `${t(roles?.find((role) => role.id === data?.authorRole)?.name)}` }</div>
                    </div>
                )
            case FixType.UpdateAuthor:
                return (
                    <div className='fix'>
                        <div className='fix-creation'>{`Teos: ${creation?.title}`}</div>
                        <div className='fix-author'>{`Autor: ${author?.authorName}`}</div>
                        <div className='fix-roles'>{ `${t(roles?.find((role) => role.id === author?.authorRole)?.name)} -> ${t(roles?.find((role) => role.id === data?.authorRole)?.name)}` }</div>
                    </div>
                )
            case FixType.RemoveAuthor:
                return (
                    <div className='fix'>
                        <div className='fix-creation'>{`Teos: ${creation?.title}`}</div>
                        <div className='fix-author'>{`Autor: ${author?.authorName}`}</div>
                        <div className='fix-roles'>{ `${t(roles?.find((role) => role.id === author?.authorRole)?.name)}` }</div>
                    </div>
                )
        }
    }

    return renderFix()
}

export default Fix