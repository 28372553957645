import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SelectInput from '../../components/SelectInput'
import { useMutation, useQuery } from '@apollo/client'
import { GetCreationTypes, GetUnrecognizedItem } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { CreationFromUnrecognized, EditUnrecognized, RemoveUnrecognized } from '../../graphql/mutations'
import { secondsToHms } from '../../util/time'
import { padWithZero } from '../../util/string'
import Button from '../../components/Button'
import DeleteIcon from '../../components/icons/delete'
import ConfirmModal from '../../components/ConfirmModal'
import PlusIcon from '../../components/icons/plus'
import SearchIcon from '../../components/icons/search'
import DurationInput from '../../components/DurationInput'
import UnrecognizedAuthors from '../../components/UnrecognizedAuthors'

const initialState = {
    title: null,
    originalTitle: null,
    year: null,
    season: null,
    episode: null,
    duration: null,
    country: null,
    producer: null,
    type: null,
    blacklisted: false,
    count: null,
    distribution: null,
    distributionCountry: null,
    distributionType: null,
    distributionYear: null,
}

const UnrecognizedCreationScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [createCreation] = useMutation(CreationFromUnrecognized)
    const [edit] = useMutation(EditUnrecognized)
    const [addLoading, setAddLoading] = useState(false)
    
    const [remove] = useMutation(RemoveUnrecognized)
    const [item, setItem] = useState(initialState)
    const [types, setTypes] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [addError, setAddError] = useState(null)

    const { data, refetch, loading } = useQuery(GetUnrecognizedItem, {
        variables: {
            id: parseInt(id),
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            console.log(data)
            if (!data?.getUnrecognizedItem) return
            setItem(data.getUnrecognizedItem)
        },
    })

    useQuery(GetCreationTypes, {
        onCompleted: (data) => {
            if (!data?.getCreationTypes) return
            setTypes(data.getCreationTypes)
        },
    })

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        let finalValue = value
        
        if (['year', 'season', 'episode', 'duration'].includes(field)) finalValue = parseInt(value)

        updateProperty(field, finalValue)
    }

    const changeType = async (type) => {
        setItem({
            ...item,
            type,
        })
        await updateProperty('type', parseInt(type))
    }

    const updateProperty = async (field, value) => {
        try {
            await edit({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('editUnrecognized: ', err)
        }
    }

    const handleRemove = async () => {
        try {
            const res = await remove({
                variables: {
                    ids: [parseInt(id)],
                },
            })

            if (!res?.data?.removeUnrecognized || res.data.removeUnrecognized === 'FAIL') return

            navigate('/unrecognized')
        } catch (err) {
            console.log('handleRemove: ', err)
        }
    }

    const handleCreateCreation = async () => {
        setAddError(null)
        setAddLoading(true)
        try {
            const res = await createCreation({
                variables: {
                    id: parseInt(id),
                },
            })

            if (res?.data?.creationFromUnrecognized !== 'FAIL') {
                navigate(`/creation/${res.data.creationFromUnrecognized}`)
                return
            }

            setAddError(t('creation.unrecognized_add_error'))
        } catch (err) {
            console.log('handleCreateCreation: ', err)
            setAddError(t('creation.unrecognized_add_error'))
        } finally {
            setAddLoading(false)
        }
    }

    const handleGoogleSearch = async () => {
        window.open(`https://www.google.com/search?q=${encodeURIComponent(item.originalTitle || item.title)}${item.episode ? `+episode+${item.episode}` : ''}${item.season ? `+season+${item.season}` : ''}+${item.year}`)
    }

    const handleIMDBSearch = async () => {
        window.open(`https://www.imdb.com/find/?q=${encodeURIComponent(item.originalTitle || item.title)}%20${item.year}`)
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='creation'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ `${t('creations.unrecognized')}: ${item.title}` }</h1>
                </div>
                <div className='inner-title--actions'>
                    <Button
                        label={ t('creation.delete_unrecognized') }
                        onClick={() => setDeleteModal(true)}
                        icon={<DeleteIcon />}
                    />
                    <Button
                        label={ t('creation.unrecognized_create') }
                        onClick={() => setAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('creation.search_google') }
                        onClick={handleGoogleSearch}
                        icon={<SearchIcon />}
                    />
                    <Button
                        label={ t('creation.search_imdb') }
                        onClick={handleIMDBSearch}
                        icon={<SearchIcon />}
                    />
                </div>
                <div className='distribution-info'>
                    <div>{ `${t('creation.distribution')}: ${item.distribution || ''} ${item.distributionYear || ''} ${item.distributionType || ''} ${item.distributionCountry || ''}` }</div>
                    <div>{ `${t('creation.views')}: ${item.count}` }</div>
                </div>
                <div className='creation-content'>
                    <div className='creation-meta'>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.title') }
                                value={item.title}
                                onChange={(e) => setField('title', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.original_title') }
                                value={item.originalTitle}
                                onChange={(e) => setField('originalTitle', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.year') }
                                value={item.year}
                                onChange={(e) => setField('year', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.producer') }
                                value={item.producer}
                                onChange={(e) => setField('producer', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.country') }
                                value={item.country}
                                onChange={(e) => setField('country', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item creation-meta--item-tvshow'>
                            <Input
                                label={ t('creation.season') }
                                value={item.season}
                                onChange={(e) => setField('season', e.target.value)}
                            />
                            <Input
                                label={ t('creation.episode') }
                                value={item.episode}
                                onChange={(e) => setField('episode', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <DurationInput
                                label={ t('creations.duration') }
                                onChange={(seconds) => setField('duration', seconds)}
                                initialValue={ item.duration }
                            />
                            <p>
                                {
                                    `${padWithZero(secondsToHms(item.duration).h, 2)}:${padWithZero(secondsToHms(item.duration).m, 2)}:${padWithZero(secondsToHms(item.duration).s, 2)}`
                                }
                            </p>
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.ida_ref') }
                                value={item.idaRef}
                                onChange={(e) => setField('idaRef', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <SelectInput
                                placeholder={t('creation.creation_type')}
                                options={types}
                                value={types.find(x => x.id === item?.type)}
                                getOptionLabel={(option) => t(option.name)}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => changeType(option.id)}
                            />
                        </div>
                    </div>
                    <UnrecognizedAuthors
                        data={data?.getUnrecognizedItem?.authors}
                        onRefresh={() => {
                            console.log('refresh')
                            refetch()
                        }}
                    />
                </div>
            </div>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('creation.remove')}
                close={() => setDeleteModal(false)}
                onConfirm={handleRemove}
                title={t('creation.confirm_delete')}
            />
            <ConfirmModal
                show={addModal}
                confirmLabel={t('creation.confirm_unrecognized')}
                close={() => {
                    setAddModal(false)
                    setAddError(null)
                }}
                onConfirm={handleCreateCreation}
                title={t('creation.unrecognized_create')}
                error={addError}
                loading={addLoading}
            />
        </div>
    )
}

export default UnrecognizedCreationScreen