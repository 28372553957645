import React from 'react'

const FiltersIcon = ({ fill }) => {

    return (
        <svg version="1.1" baseProfile="tiny" fill='none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xmlSpace="preserve">
            <path d="M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10M19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10M19 14V20M19 10V4M12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16ZM12 16V4M5 8C6.10457 8 7 7.10457 7 6C7 4.89543 6.10457 4 5 4C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8ZM5 8V20" stroke={fill ? fill : '#000'} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default FiltersIcon