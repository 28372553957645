import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { GetDistributionReports, GetDistributionTotal, GetViewFilters } from '../../graphql/queries'
import './style.scss'
import GearIcon from '../../components/icons/gear'
import PlusIcon from '../../components/icons/plus'
import ExportIcon from '../../components/icons/excel'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import Tasks from '../../components/Tasks'
import { ReportTypes, TaskTypes } from '../../util/const'
import CreateReportModal from '../../components/CreateReportModal'
import { formatDate } from '../../util/format'
import ReportFilters from '../../components/ReportFilters'
import { Countries } from '../../util/countries'
import ExportModal from '../../components/ExportModal'
import Total from '../../components/Total'
import SocietyExportModal from '../../components/SocietyExportModal'
import ManageReportsModal from '../../components/ManageReportsModal'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'name',
        label: 'Jaotus',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'points',
        label: 'Punktid',
    },
    {
        value: 'pointValue',
        label: 'Bruto summa',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const ReportsScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [showModal, setShowModal] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    const [societyExportModal, setSocietyExportModal] = useState(false)
    const [showReportsModal, setShowReportsModal] = useState(false)
    const [filters, setFilters] = useState(initialFilterState)
    const [extraFilters, setExtraFilters] = useState()
    const [total, setTotal] = useState(null)

    const { refetch: getTotal } = useQuery(GetDistributionTotal, {
        skip: true,
    })

    useEffect(() => {
        (async () => {
            if (extraFilters?.year && extraFilters?.type) {
                const res = await getTotal({
                    year: parseInt(extraFilters.year),
                    type: extraFilters.type,
                })

                if (res?.data?.getDistributionTotal) setTotal(res?.data?.getDistributionTotal)
            }
        })()
    }, [extraFilters])

    useQuery(GetViewFilters, {
        onCompleted: (data) => {
            if (data?.getViewFilters) {
                let yearList = []
                let distList = []
                if (data.getViewFilters.years) {
                    yearList = data.getViewFilters.years.map(x => ({
                        value: x,
                        label: x,
                    }))
                }

                if (data.getViewFilters.distributions) {
                    distList = data.getViewFilters.distributions.map(x => ({
                        value: x,
                        label: x,
                    }))
                }

                setFilters({
                    ...filters,
                    distributions: distList,
                    years: yearList,
                })
            }
        },
    })

    const handleRowClick = (row) => {
        navigate(`/distribution/${row?.report?.id}/${row?.name}`)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'pointValue':
                return `${(parseFloat(row?.report ? row.report[field] : 0) * parseFloat(row['points'])).toFixed(2)} €`
            case 'createdAt':
                return formatDate(row?.report ? row.report[field] : null)
            case 'year':
                return row.report?.year
            case 'type':
                return row.report?.type
            case 'country':
                return Countries.country[row[field]]
            case 'points':
                return parseFloat(row[field]).toFixed()
            default:
                return row[field]
        }
    }

    const handleSuccess = (reportId, name) => {
        tableRef.current?.refresh()
        setShowModal(false)
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    return (
        <div className='reports'>
            <div className='inner'>
                <div className='inner-title'>
                    <div className='inner-title--left'>
                        <h1>{ t('reports.title') }</h1>
                        <Button
                            label={ t('reports.add_new') }
                            onClick={() => setShowModal(true)}
                            icon={<PlusIcon />}
                        />
                        <Button
                            label={ t('reports.export') }
                            onClick={() => setExportModal(true)}
                            icon={<ExportIcon />}
                        />
                        <Button
                            label={ t('reports.export_society') }
                            onClick={() => setSocietyExportModal(true)}
                            icon={<ExportIcon />}
                        />
                        <Button
                            label={ t('reports.manage_reports') }
                            onClick={() => setShowReportsModal(true)}
                            icon={<GearIcon />}
                        />
                    </div>
                    <div className='inner-title--right'>
                        <div
                            className='icon-btn'
                            onClick={() => navigate('/report-settings')}
                        >
                            <GearIcon />
                        </div>
                    </div>
                </div>
                <Tasks
                    type={TaskTypes.Report}
                />
                <div className='below-title'>
                    <ReportFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allowed={{
                            type: true,
                            year: true,
                            distribution: true,
                        }}
                    />
                    <Total
                        total={total}
                    />
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetDistributionReports}
                        fieldConditions={fieldConditions}
                        queryKey={'getDistributionReports'}
                        hideInputs={true}
                        initialOrderBy={'year'}
                        onRowClick={handleRowClick}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
                <CreateReportModal
                    show={showModal}
                    filters={filters}
                    close={() => setShowModal(false)}
                    onSuccess={handleSuccess}
                />
                <ExportModal
                    show={exportModal}
                    close={() => setExportModal(false)}
                />
                <SocietyExportModal
                    show={societyExportModal}
                    close={() => setSocietyExportModal(false)}
                />
                <ManageReportsModal
                    show={showReportsModal}
                    close={() => setShowReportsModal(false)}
                />
            </div>
        </div>
    )
}

export default ReportsScreen