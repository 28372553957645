import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import FileInput from './FileInput'

const FileUpload = ({
    handleUpload,
    onChange,
    loading,
}) => {

    const { t } = useTranslation()
    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const handleUploadClick = async () => {
        if (handleUpload) {
            setUploading(true)
            await handleUpload(file)
            setUploading(false)
        }
    }

    const handleChange = (e) => {
        if (onChange) onChange(e.target.files[0])
        setFile(e.target.files[0])
    }

    return (
        <div className='files-upload'>
            <div className='upload-file'>
                {
                    file ?
                    <div className='upload-file--file'>
                        <div className='upload-file--filename'>{ file?.name }</div>
                        <Button
                            className={'btn-small'}
                            onClick={() => setFile(null)}
                            label={t('file.remove')}
                        />
                    </div>
                    :
                    <div className='upload-file--type'>
                        <FileInput key={file?.name} label={t('Vali fail arvutist')} onChange={handleChange} />
                    </div>
                }
                {
                    onChange !== undefined ?
                    <></>
                    :
                    <Button
                        label={'Lae üles'}
                        disabled={!file}
                        loading={loading || uploading}
                        onClick={handleUploadClick}
                    />
                }
            </div>
        </div>
    )
}

export default FileUpload