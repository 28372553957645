import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { AddCreationFromIda } from '../graphql/mutations'

const initialErrorsState = {
    ref: null,
    generic: null,
}

const AddIDACreationModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [idaRef, setIdaRef] = useState('')
    const [add, { loading }] = useMutation(AddCreationFromIda)
    const [errors, setErrors] = useState(initialErrorsState)

    const handleAdd = async () => {
        if (hasInputErrors()) return
        try {
            const res = await add({
                variables: {
                    ref: idaRef,
                },
            })


            if (!res?.data?.addCreationFromIda) {
                setErrors({
                    ...initialErrorsState,
                    generic: t('creations.add_error'),
                })
                return
            }

            if (onSuccess && res.data.addCreationFromIda.data) {
                onSuccess(res.data.addCreationFromIda.data)
                return
            }

            switch (res.data.addCreationFromIda.error) {
                case 'FAIL':
                    setErrors({
                        ...initialErrorsState,
                        generic: t('creations.add_error'),
                    })
                    return
                case 'EXISTS':
                    setErrors({
                        ...initialErrorsState,
                        generic: t('creations.existing_error'),
                    })
                    return
                case 'SERVER_ERROR':
                    setErrors({
                        ...initialErrorsState,
                        generic: t('creations.ida_server_error'),
                    })
                    return
                default:
                    setErrors({
                        ...initialErrorsState,
                        generic: res.data.addCreationFromIda.error,
                    })
                    return
            }
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('creations.add_error'),
            })
        }
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!idaRef) {
            hasErrors = true
            errorState.ref = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
    }

    return (
        <Modal
            className={'modal-creation modal-creation-ida'}
            show={show}
            close={handleClose}
            title={t('creations.add_new_ida')}
        >
            <Input
                label={t('creations.ida_ref')}
                value={idaRef}
                onChange={(e) => setIdaRef(e.target.value)}
                error={errors.ref}
            />

            {
                errors.generic ?
                    <div className='error-message'>
                        <span>{errors.generic}</span>
                    </div>
                    :
                    <></>
            }

            <Button
                label={t('creations.add_new')}
                onClick={handleAdd}
                loading={loading}
                disabled={loading}
            />
        </Modal>
    )
}

export default AddIDACreationModal