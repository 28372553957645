import { pdf, PDFViewer } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../util/format'
import Button from './Button'
import DownloadIcon from './icons/download'
import IncomeTaxDocument from '../documents/income-tax'

const PdfViewer = ({
    document,
    data,
    showViewer,
    showLoading,
}) => {

    const { t } = useTranslation()

    const getDocument = () => {
        switch (document) {
            case 'income-tax':
                return <IncomeTaxDocument data={data} />
        }
    }

    const handleSave = async () => {
        let fileName = 'Tellimus.pdf'
        switch (document) {
            case 'income-tax':
                fileName = `EAÜ ${data.year} a Tulumaksuõiend ${formatDate(new Date())}.pdf`
        }
        const blob = await pdf(getDocument()).toBlob()
        saveAs(blob, fileName)
    }

    return (
        <div className='pdf-viewer'>
            <div className='inner'>
                <div className='pdf-viewer--actions'>
                    <Button
                        label={t('payouts.save_statement')}
                        onClick={handleSave}
                        disabled={!data || showLoading}
                        loading={showLoading}
                        icon={<DownloadIcon />}
                    />
                </div>
                {
                    showViewer ?
                    <PDFViewer>
                        { getDocument() }
                    </PDFViewer>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default PdfViewer