import { useQuery } from '@apollo/client'
import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingComponent from './Loading'
import Pagination from './Pagination'
import ChevronBoldIcon from './icons/chevron-bold'
import { useSearchParams } from 'react-router-dom'
import EmptyList from './EmptyList'

const FilterTable = forwardRef(({
    query,
    queryKey,
    queryOptions,
    fieldConditions,
    includeFields,
    initialOrderBy,
    onRowClick,
    onRowDoubleClick,
    extraFilters,
    statusColorEnabled,
    activeRows,
    rowBackgroundColor,
    rowClass,
    ignoredKeys,
    useExtraFilters,
    subscriptions,
    onFiltersChanged,
    initialSort,
    hideInputs,
    allowSelect,
    emptyMessage,
}, ref) => {

    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()

    const [filters, setFilters] = useState([])
    const [orderBy, setOrderBy] = useState(initialOrderBy)
    const [sort, setSort] = useState(initialSort ? initialSort : 'DESC')
    const table = useRef()
    const page = useRef(searchParams.get('page') ? parseInt(searchParams.get('page')) - 1 : 0)
    const limit = useRef(100)
    const extraFiltersRef = useRef()
    const [inputs, setInputs] = useState({})
    const [selectedRow, setSelectedRow] = useState(null)

    const { data, refetch, loading, subscribeToMore } = useQuery(query, {
        ...queryOptions,
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            if (onFiltersChanged) onFiltersChanged()
        },
    })

    useEffect(() => {
        if (includeFields) {
            const inputFields = {}
            includeFields.map(x => inputFields[x.value] = '')
            setInputs(inputFields)
        }
        if (!useExtraFilters) {
            refetch({
                ...queryOptions?.variables,
                page: page.current,
                limit: limit.current,
                sort,
                filters,
                orderBy,
                ...(extraFilters && {
                    extraFilters,
                }),
            })
        }

        const unsubscribe = []

        if (subscriptions?.length > 0) {
            subscriptions.forEach(({ document, updateQuery }) => {
                const unsub = subscribeToMore({
                    document,
                    updateQuery,
                })
                unsubscribe.push(unsub)
            })
        }

        return () => {
            unsubscribe.forEach(unsub => {
                unsub()
            })
        }
    }, [])

    useEffect(() => {
        if (!useExtraFilters || !extraFilters) return
        if (!Object.is(extraFiltersRef.current, extraFilters)) {
            page.current = searchParams.get('page') ? parseInt(searchParams.get('page')) - 1 : 0
            const sortParam = searchParams.get('sort')
            const orderByParam = searchParams.get('orderBy')

            refetch({
                ...queryOptions?.variables,
                page: page.current,
                limit: limit.current,
                sort: sortParam || sort,
                filters,
                orderBy: orderByParam || orderBy,
                ...(extraFilters && {
                    extraFilters: {
                        ...extraFilters,
                        orderBy: undefined,
                        sort: undefined,
                    },
                }),
            })
            extraFiltersRef.current = extraFilters

            if (orderByParam) setOrderBy(orderByParam)
            if (sortParam) setSort(sortParam)
        }
    }, [extraFilters])

    useImperativeHandle(ref, () => ({
        refresh () {
            handleFiltersChanged()
        },
        getTableRows () {
            return table.current.rows
        },
        getTableRowsData () {
            return data[queryKey]?.rows
        },
        getCurrentPage () {
            return page.current
        },
    }))

    const getColValue = (row, field) => {
        const conditionResult = fieldConditions ? fieldConditions(row, field) : null
        return conditionResult ? conditionResult : (typeof row[field] === 'object' ? '' : row[field]) 
    }

    const handleFiltering = (field, value) => {
        if (value <= 1 || value.length <= 1) {
            const removed = filters.filter(x => x.field !== field)
            setFilters(removed)
            return
        }
        const filterIndex = filters.findIndex(x => x.field === field)
        if (filterIndex > -1) {
            const newFilters = filters
            newFilters[filterIndex] = {
                field,
                value,
            }
            setFilters(newFilters)
            return
        }
        const newFilters = [
            ...filters,
            {
                field,
                value,
            },
        ]
        setFilters(newFilters)
    }

    const handleFiltersChanged = () => {
        refetch({
            page: page.current,
            limit: limit.current,
            filters,
            sort,
            orderBy,
            ...(extraFilters && {
                extraFilters,
            }),
        })
    }

    const setPageParam = (page) => {
        const newParams = {}
        for (const [key, value] of searchParams.entries()) {
            newParams[key] = value
        }

        newParams.page = page

        setSearchParams(newParams)
    }

    const handleSort = (key) => {
        if (ignoredKeys?.includes(key)) return
        const newSort = sort === 'ASC' ? 'DESC' : 'ASC'
        setOrderBy(key)
        setSort(newSort)

        page.current = 0

        setSearchParams({
            ...searchParams,
            page: 1,
            sort: newSort,
            orderBy: key,
        })

        refetch({
            page: page.current,
            limit: limit.current,
            filters,
            sort: newSort,
            orderBy: key,
            ...(extraFilters && {
                extraFilters,
            }),
        })
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') handleFiltersChanged()
    }

    const getPreviousPage = () => {
        page.current = page.current - 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
        setPageParam(page.current + 1)
    }
    
    const getNextPage = () => {
        page.current = page.current + 1
        refetch({
            page: page.current,
            limit: limit.current,
        })
        setPageParam(page.current + 1)
    }

    const handlePageClick = (pageNumber) => {
        page.current = pageNumber
        refetch({
            page: page.current,
            limit: limit.current,
        })
        setPageParam(page.current + 1)
    }

    if (loading) return <LoadingComponent />

    if (!data || !data[queryKey] || !data[queryKey].rows || data[queryKey].rows.length < 1) return <EmptyList message={emptyMessage} />

    return (
        <div className='filter-table'>
            <table id="filter-table" ref={table}>
                <thead>
                    <tr>
                        {
                            includeFields.map((header) =>
                                <th key={`filter-table-header-${header.value}`}>
                                    <div className='filter-table-header--label' onClick={() => handleSort(header.value)}>
                                        <span>{ t(header.label) }</span>
                                        {
                                            header.value === orderBy ?
                                            <div className={`filter-table-header--sort filter-table-header--sort-${sort === 'ASC' ? 'asc' : 'desc'}`}>
                                                <ChevronBoldIcon />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    {
                                        hideInputs ?
                                        <></>
                                        :
                                        <input
                                            value={inputs[header.value]}
                                            onChange={(e) => {
                                                setInputs({
                                                    ...inputs,
                                                    [header.value]: e.target.value,
                                                })
                                                handleFiltering(header.value, e.target.value)
                                            }}
                                            onKeyUp={handleEnter}
                                        />
                                    }
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data[queryKey] && data[queryKey].rows?.map((row, index) =>
                            <tr
                                key={`filter-table-row-${index}`}
                                className={
                                    `${Array.isArray(activeRows) && activeRows.some(x => x.id === row.id) ? 'filter-table-row--selected ' : ''}${rowClass ? rowClass(row) : ''}`
                                }
                                style={{
                                    ...(statusColorEnabled && rowBackgroundColor && {
                                        backgroundColor: rowBackgroundColor(row),
                                    }),
                                }}
                                onClick={(e) => {
                                    if (onRowClick) {
                                        if (allowSelect && e.shiftKey && selectedRow) {
                                            const first = data[queryKey].rows.indexOf(selectedRow)
                                            const currentIndex = index
                                            let selectedItems = []
                                            
                                            if (first > currentIndex) selectedItems = data[queryKey].rows.filter((x, i) => i <= first && i >= currentIndex)
                                            if (first < currentIndex) selectedItems = data[queryKey].rows.filter((x, i) => i >= first && i <= currentIndex)

                                            onRowClick(selectedItems, e)
                                            return
                                        }
                                        setSelectedRow(row)
                                        onRowClick(row, e)
                                    }
                                }}
                                onDoubleClick={(e) => {
                                    if (onRowDoubleClick) onRowDoubleClick(row, e)
                                }}
                            >
                                {
                                    includeFields.map((field, fieldIndex) =>
                                        <td key={`filter-table-row-${index}-col-${fieldIndex}`}>
                                            { getColValue(row, field.value) }
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <Pagination
                total={data && data[queryKey] && data[queryKey].count}
                onPrev={getPreviousPage}
                onNext={getNextPage}
                current={page.current}
                limit={limit.current}
                onPageClick={handlePageClick}
            />
        </div>
    )
})

export default FilterTable