import React, { useEffect, useState } from 'react'
import Button from './Button'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import SearchIcon from './icons/search'
import { CheckIDACreationChanges } from '../graphql/mutations'
import { GetTasks } from '../graphql/queries'

const IdaCheckButton = () => {

    const { t } = useTranslation()
    const [checkIDA] = useMutation(CheckIDACreationChanges)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const { data: tasksData, refetch, startPolling, stopPolling } = useQuery(GetTasks, {
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (tasksData?.getTasks?.rows?.some(x => x.type === 'IDA_CHECK')) {
            setDisabled(true)
            setLoading(false)
            startPolling(2000)
            return
        }

        stopPolling()
        setLoading(false)
        setDisabled(false)
    }, [tasksData])

    const handleIdaCheck = async () => {
        setLoading(true)
        startPolling(2000)

        try {
            await checkIDA({
                refetchQueries: ['getTasks'],
            })
        } catch (err) {
            console.log('handleIdaCheck: ', err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Button
            label={ t('creations.check_ida') }
            onClick={handleIdaCheck}
            disabled={disabled}
            loading={loading}
            icon={<SearchIcon />}
        />
    )
}

export default IdaCheckButton