import React, { useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GetUserBalance } from '../../graphql/queries'
import './style.scss'
import UserBalanceFilters from '../../components/UserBalanceFilters'

const displaySum = (sum) => {
    return sum && sum > 0 ? `${parseFloat(sum).toFixed(2)}€` : '0€'
}

const UserBalanceScreen = ({
    role,
    authorId,
}) => {
    const filtersRef = useRef()
    const [extraFilters, setExtraFilters] = useState()

    const { data } = useQuery(GetUserBalance, {
        fetchPolicy: 'no-cache',
        skip: !extraFilters?.year,
        variables: {
            year: extraFilters?.year
        },
    })

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    if (role !== 'author' && !authorId) return <></>

    return (
        <div className='user-balance'>
            <div className='inner'>
                <div className='below-title'>
                    <UserBalanceFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                    />
                </div>
                <div className='user-balance-data'>
                    <div className='user-balance-data-item'>
                        <div className='user-balance-data-item-title'>{ 'Välisjaotused' }</div>
                        <div className='user-balance-data-item-value'>{ displaySum(data?.getUserBalance?.foreignSum) }</div>
                    </div>
                    <div className='user-balance-data-item'>
                        <div className='user-balance-data-item-title'>{ 'Sisejaotused' }</div>
                        <div className='user-balance-data-item-value'>{ displaySum(data?.getUserBalance?.distSum) }</div>
                    </div>
                    <div className='user-balance-data-item'>
                        <div className='user-balance-data-item-title'>{ 'Boonused' }</div>
                        <div className='user-balance-data-item-value'>{ displaySum(data?.getUserBalance?.bonusSum) }</div>
                    </div>
                    <div className='user-balance-data-item'>
                        <div className='user-balance-data-item-title'>{ 'Väljamaksed' }</div>
                        <div className='user-balance-data-item-value'>{ displaySum(data?.getUserBalance?.payoutSum) }</div>
                    </div>
                    <div className='user-balance-data-item user-balance-data-item-total'>
                        <div className='user-balance-data-item-title'>{ 'Saldo' }</div>
                        <div className='user-balance-data-item-value'>{ displaySum(data?.getUserBalance?.total) }</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserBalanceScreen