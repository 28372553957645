import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from './icons/search'
import Input from './Input'
import DateInput from './DateInput'
import { parseDate } from '../util/format'

const defaultFromDate = new Date()
defaultFromDate.setDate(defaultFromDate.getDate() - 30)

const initialState = {
    query: null,
    fromDate: defaultFromDate,
    toDate: new Date(),
}

const BalanceFilters = forwardRef(({
    onFilterChange,
}, ref) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters filters-balance'>
            <div className='filters-search'>
                <Input
                    placeholder={ t('balances.search_by') }
                    onChange={(e) => setField('query', e.target.value)}
                    value={filters.query}
                    icon={<SearchIcon />}
                    label={t('balances.search')}
                />
            </div>
            <div className='filters-date-between'>
                <DateInput
                    label={t('balances.from_date')}
                    value={filters.fromDate ? new Date(filters.fromDate) : null}
                    onChange={(val) => setField('fromDate', parseDate(val))}
                />
                <DateInput
                    label={t('balances.to_date')}
                    value={filters.toDate ? new Date(filters.toDate) : null}
                    onChange={(val) => setField('toDate', parseDate(val))}
                />
            </div>
        </div>
    )
})

export default BalanceFilters