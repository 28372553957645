const UserRoles = {
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Client: 'CLIENT',
}

const CreationTypes = {
    Series: 'SERIES',
    Documentary: 'DOCUMENTARY',
    Movie: 'MOVIE',
    Animation: 'ANIMATION',
}

const ReportTypes = {
    VOD: 'VOD',
    TV: 'TV',
}

const TaskTypes = {
    ViewImport: 'VIEW_IMPORT',
    CreationImport: 'CREATION_IMPORT',
    SocietyImport: 'SOCIETY_IMPORT',
    BonusImport: 'BONUS_IMPORT',
    PayoutImport: 'PAYOUT_IMPORT',
    UserImport: 'USER_IMPORT',
    ForeignImport: 'FOREIGN_IMPORT',
    Report: 'REPORT',
}

const FixType = {
    UpdateAuthor: 'UPDATE_AUTHOR',
    AddAuthor: 'ADD_AUTHOR',
    RemoveAuthor: 'REMOVE_AUTHOR',
    AddCreation: 'ADD_CREATION',
}

const FixStatus = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
}

export {
    UserRoles,
    CreationTypes,
    ReportTypes,
    TaskTypes,
    FixStatus,
    FixType,
}