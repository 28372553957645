import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from './icons/search'
import Input from './Input'

const initialState = {
    query: null,
    author: '',
}

const CreationFilters = ({
    onFilterChange,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    useEffect(() => {
        const loadFilters = {
            query: null,
        }

        for (const [param, value] of searchParams.entries()) {
            if (param === 'page') continue

            let finalValue = value

            if (param === 'year') finalValue = parseInt(value)
            if (param === 'hasAuthors') finalValue = value === 'true' ? true : false

            loadFilters[param] = finalValue
        }

        setFilters({
            ...loadFilters,
        })

        if (onFilterChange) onFilterChange({
            ...loadFilters,
        })
    }, [searchParams])

    const assignSearchParams = (params) => {
        const newParams = {}
        for (const [key, value] of Object.entries(params)) {
            if (!value) continue
            newParams[key] = value
        }

        setSearchParams({
            ...newParams,
            page: 1,
        })
    }

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)
        assignSearchParams(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters'>
            <div className='filters-search'>
                <Input
                    placeholder={t('filters.search_creation')}
                    onChange={(e) => setField('query', e.target.value)}
                    label={t('filters.search_creation_label')}
                    value={filters.query}
                    icon={<SearchIcon />}
                />

            </div>
            <div className='filters-search-author'>
                <Input
                    placeholder={t('filters.search_by_author')}
                    onChange={(e) => setField('author', e.target.value)}
                    label={t('filters.search_by_author_label')}
                    value={filters.author}
                    icon={<SearchIcon />}
                />
            </div>
        </div>
    )
}

export default CreationFilters