import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { GetCreations } from '../../graphql/queries'
import './style.scss'
import { RemoveCreation } from '../../graphql/mutations'
import FilterTable from '../../components/FilterTable'
import CreationFilters from '../../components/CreationFilters'
import AddCreationModal from '../../components/AddCreationModal'
import Button from '../../components/Button'
import PlusIcon from '../../components/icons/plus'
import { formatDate } from '../../util/format'
import { capitalizeName } from '../../util/string'
import ImportIcon from '../../components/icons/import'
import ImportCreationsModal from '../../components/ImportCreationsModal'
import Tasks from '../../components/Tasks'
import { TaskTypes } from '../../util/const'
import DeleteIcon from '../../components/icons/delete'
import ConfirmModal from '../../components/ConfirmModal'
import AddIDACreationModal from '../../components/AddIDACreationModal'
import IdaCheckButton from '../../components/IdaCheckButton'

const includeFields = [
    {
        value: 'title',
        label: 'Pealkiri',
    },
    {
        value: 'originalTitle',
        label: 'Orig. pealkiri',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'season',
        label: 'Hooaeg',
    },
    {
        value: 'episode',
        label: 'Seeria nr',
    },
    {
        value: 'episodeTitle',
        label: 'Seeria pealkiri',
    },
    {
        value: 'creationType',
        label: 'Liik',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'createdAt',
        label: 'Lisatud',
    },
]

const CreationsScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const tableRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showAddIdaModal, setShowAddIdaModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)

    const [removeCreation] = useMutation(RemoveCreation)

    const handleImportSuccess = async () => {
        tableRef.current?.refresh()
        setShowImportModal(false)
    }

    const handleImportFail = async () => {
        tableRef.current?.refresh()
    }

    const fieldConditions = (row, field) => {
        if (field === 'creationType' && row[field]?.name) return t(row[field].name)
        if (field === 'createdAt') return formatDate(row[field])
        if (['originalTitle', 'title'].includes(field)) return capitalizeName(row[field])
        return row[field]
    }

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const handleRowDoubleClick = (row, e) => {
        if (e.ctrlKey || e.metaKey) {
            const tab = window.open(`/creation/${row.id}`)
            tab.focus()
            return
        }
        navigate(`/creation/${row.id}`)
    }

    const handleDelete = async () => {
        try {
            const res = await removeCreation({
                variables: {
                    ids: selectedRows.map(x => x.id),
                },
            })

            if (res?.data?.removeCreation === 'SUCCESS') {
                tableRef.current?.refresh()
                setDeleteModal(false)
                setSelectedRows([])
            }

            console.log('Delete creation:', res)
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const handleFilterChange = async (filters) => {
        setSelectedRows([])
        setExtraFilters(filters)
    }

    const handleSuccess = (creationId) => {
        setShowAddModal(false)
        navigate(`/creation/${creationId}`)
    }

    return (
        <div className='creations'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('creations.title') }</h1>
                    <Button
                        label={ t('creations.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('creations.add_new_ida') }
                        onClick={() => setShowAddIdaModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('creations.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                    <IdaCheckButton />
                    {
                        selectedRows.length > 0 ?
                        <>
                            <Button
                                label={ t('views.delete') }
                                onClick={() => setDeleteModal(true)}
                                icon={<DeleteIcon />}
                            />
                        </>
                        :
                        <></>
                    }
                </div>
                <Tasks
                    type={TaskTypes.CreationImport}
                />
                <CreationFilters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetCreations}
                        fieldConditions={fieldConditions}
                        queryKey={'getCreations'}
                        hideInputs={true}
                        includeFields={includeFields}
                        initialOrderBy={'title'}
                        initialSort={'ASC'}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        activeRows={selectedRows}
                        allowSelect={true}
                    />
                </div>
            </div>
            <AddCreationModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
            <AddIDACreationModal
                show={showAddIdaModal}
                close={() => setShowAddIdaModal(false)}
                onSuccess={handleSuccess}
            />
            <ImportCreationsModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
                onFail={handleImportFail}
            />
            <ConfirmModal
                show={deleteModal}
                confirmLabel={selectedRows.length > 1 ? t('creation.remove_items') : t('creation.remove')}
                close={() => setDeleteModal(false)}
                onConfirm={handleDelete}
                title={selectedRows.length > 1 ? t('creation.confirm_remove_items') : t('creation.confirm_delete')}
            />
        </div>
    )
}

export default CreationsScreen