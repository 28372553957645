import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GetAuthorCreations } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import CreationFilters from '../../components/CreationFilters'
import Button from '../../components/Button'
import PlusIcon from '../../components/icons/plus'
import { formatDate } from '../../util/format'
import { capitalizeName } from '../../util/string'
import AddUserCreationModal from '../../components/AddUserCreationModal'

const includeFields = [
    {
        value: 'title',
        label: 'Pealkiri',
    },
    {
        value: 'originalTitle',
        label: 'Orig. pealkiri',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'season',
        label: 'Hooaeg',
    },
    {
        value: 'episode',
        label: 'Seeria nr',
    },
    {
        value: 'episodeTitle',
        label: 'Seeria pealkiri',
    },
    {
        value: 'creationType',
        label: 'Liik',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'createdAt',
        label: 'Lisatud',
    },
]

const UserCreationsScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const tableRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)

    const fieldConditions = (row, field) => {
        if (field === 'creationType' && row[field]?.name) return t(row[field].name)
        if (field === 'createdAt') return formatDate(row[field])
        if (['originalTitle', 'title'].includes(field)) return capitalizeName(row[field])
        return row[field]
    }

    const handleRowClick = (row, e) => {
        if (e.ctrlKey || e.metaKey) {
            const tab = window.open(`/user-creation/${row.id}`)
            tab.focus()
            return
        }
        navigate(`/user-creation/${row.id}`)
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleSuccess = (creationId) => {
        setShowAddModal(false)
        navigate(`/user-creation/${creationId}`)
    }

    return (
        <div className='creations'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('creations.title') }</h1>
                    <Button
                        label={ t('creations.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                </div>
                <CreationFilters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetAuthorCreations}
                        fieldConditions={fieldConditions}
                        queryKey={'getAuthorCreations'}
                        hideInputs={true}
                        includeFields={includeFields}
                        initialOrderBy={'title'}
                        initialSort={'ASC'}
                        onRowClick={handleRowClick}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        queryOptions={{
                            fetchPolicy: 'no-cache',
                        }}
                    />
                </div>
            </div>
            <AddUserCreationModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
        </div>
    )
}

export default UserCreationsScreen