import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { GetAuthorsReports, GetReport, GetViewFilters } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Tasks from '../../components/Tasks'
import { ReportTypes, TaskTypes } from '../../util/const'
import CreateReportModal from '../../components/CreateReportModal'
import { formatDate } from '../../util/format'
import ReportFilters from '../../components/ReportFilters'
import { capitalizeName } from '../../util/string'
import LoadingComponent from '../../components/Loading'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'authorName',
        label: 'Autor',
    },
    {
        value: 'points',
        label: 'Punktid',
    },
    {
        value: 'total',
        label: 'Bruto summa',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const AuthorsReportsScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [filters, setFilters] = useState(initialFilterState)
    const [extraFilters, setExtraFilters] = useState()

    useQuery(GetViewFilters, {
        onCompleted: (data) => {
            let yearList, distList

            if (data?.getViewFilters?.years) {
                yearList = data.getViewFilters.years.map(x => ({
                    value: x,
                    label: x,
                }))
            }

            if (data?.getViewFilters?.distributions) {
                distList = data.getViewFilters.distributions.map(x => ({
                    value: x,
                    label: x,
                }))
            }

            setFilters({
                ...filters,
                distributions: distList,
                years: yearList,
            })
        },
    })

    const handleRowClick = (row) => {
        navigate(`/authors-reports/${row.reportId}/${row.authorName.toLowerCase()}`)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'total':
                return `${parseFloat(row[field]).toFixed(2)} €`
            case 'createdAt':
                return formatDate(row[field])
            case 'authorName':
                return capitalizeName(row[field])
            case 'points':
                return parseFloat(row[field]).toFixed()
            default:
                return row[field]
        }
    }

    const handleSuccess = (reportId, name) => {
        navigate(`/distribution/${reportId}/${name}`)
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleFilteringLoaded = () => {
        setLoading(false)
    }

    return (
        <div className='authors-reports'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('authors_reports.title') }</h1>
                </div>
                <Tasks
                    type={TaskTypes.Report}
                />
                <ReportFilters
                    ref={filtersRef}
                    onLoaded={handleFilteringLoaded}
                    onFilterChange={handleFilterChange}
                    allowed={{
                        query: true,
                        year: true,
                        type: true,
                    }}
                />
                {
                    loading ?
                    <LoadingComponent />
                    :
                    <div className='filter-table--container'>
                        <FilterTable
                            ref={tableRef}
                            query={GetAuthorsReports}
                            fieldConditions={fieldConditions}
                            queryKey={'getAuthorsReports'}
                            hideInputs={true}
                            initialOrderBy={'authorName'}
                            initialSort={'ASC'}
                            onRowClick={handleRowClick}
                            includeFields={includeFields}
                            queryOptions={{
                                fetchPolicy: 'no-cache',
                            }}
                            extraFilters={extraFilters}
                            useExtraFilters={true}
                        />
                    </div>
                }
                <CreateReportModal
                    show={showModal}
                    filters={filters}
                    close={() => setShowModal(false)}
                    onSuccess={handleSuccess}
                />
            </div>
        </div>
    )
}

export default AuthorsReportsScreen