import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import Modal from './Modal'
import PdfViewer from './PdfViewer'
import { GetIncomeTaxStatement, GetPayoutYears, SearchUsers } from '../graphql/queries'
import SelectInput from './SelectInput'
import { useAuth } from '../providers/Auth'
import ActivityIndicator from './ActivityIndicator'
import { UserRoles } from '../util/const'

const initialErrorState = {
    year: null,
    generic: null,
}

const IncomeTaxModal = ({
    show,
    close,
}) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const [errors, setErrors] = useState(initialErrorState)
    const [year, setYear] = useState()
    const [years, setYears] = useState()
    const [selectedUser, setSelectedUser] = useState()

    const { refetch: searchUsers } = useQuery(SearchUsers, {
        skip: true,
    })

    const { refetch: getYears } = useQuery(GetPayoutYears, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getPayoutYears) return
            const yearOptions = data.getPayoutYears.map(x => ({
                value: x,
                label: x,
            }))
            setYears(yearOptions)
        },
    })

    const { loading, refetch: getStatement, data: statement } = useQuery(GetIncomeTaxStatement, {
        fetchPolicy: 'no-cache',
        variables: {
            year,
        },
    })
    
    useEffect(() => {
        if (!selectedUser) return
        setYear(null)

        getYears({
            pid: selectedUser.pid,
        })
    }, [selectedUser])

    useEffect(() => {
        if (!year?.value) return

        if (user?.role === UserRoles.Admin) {
            getStatement({
                year: year.value,
                pid: selectedUser.pid,
            })
            return
        }

        getStatement({
            year: year.value,
        })
    }, [year])

    const loadUsers = async (input) => {
        if (input.length < 2) return
        const res = await searchUsers({
            input,
        })
        return res?.data?.searchUsers
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorState)
        setYear(null)
        setSelectedUser(null)
    }

    return (
        <Modal
            className={'modal-income-tax'}
            show={show}
            close={handleClose}
            title={ t('users.create_income_tax_statement') }
        >
            {
                user?.role === UserRoles.Admin ?
                <SelectInput
                    placeholder={t('users.search_user')}
                    load={loadUsers}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(option) => setSelectedUser(option)}
                    loadingMessage={() => t('users.loading_users')}
                    noOptionsMessage={() => t('users.type_to_search')}
                />
                :
                <></>
            }
            
            <SelectInput
                label={ t('payouts.choose_year') }
                options={years}
                onChange={(option) => setYear(option)}
                value={year}
                error={errors.year}
            />

            {
                loading ?
                <div className='modal-income-tax--loading'>
                    <p>{ t('payouts.loading_statement') }</p>
                    <div className='modal-income-tax--loading-indicator'>
                        <ActivityIndicator />
                    </div>
                </div>
                :
                <></>
            }

            {
                statement?.getIncomeTaxStatement && !loading ?
                <PdfViewer
                    document={'income-tax'}
                    data={{
                        ...statement.getIncomeTaxStatement,
                        year: year?.value,
                        user,
                    }}
                />
                :
                <></>
            }

            {
                errors.generic ?
                <div className='error-message'>
                    <span>{ errors.generic }</span>
                </div>
                :
                <></>
            }
        </Modal>
    )
}

export default IncomeTaxModal