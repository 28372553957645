import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GetCreation, GetCreationTypes } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { secondsToHms } from '../../util/time'
import { padWithZero } from '../../util/string'
import UserAuthors from '../../components/UserAuthors'

const initialState = {
    title: null,
    originalTitle: null,
    episodeTitle: null,
    year: null,
    season: null,
    episode: null,
    duration: null,
    country: null,
    producer: null,
    type: null,
    blacklisted: false,
}

const UserCreationScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const [item, setItem] = useState(initialState)
    const [types, setTypes] = useState([])

    const { data, refetch, loading } = useQuery(GetCreation, {
        variables: {
            id: parseInt(id),
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getCreation) return
            setItem(data.getCreation)
        },
    })

    useQuery(GetCreationTypes, {
        onCompleted: (data) => {
            if (!data?.getCreationTypes) return
            setTypes(data.getCreationTypes)
        },
    })

    if (loading) return <ActivityIndicator />

    return (
        <div className='creation'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ item.title }</h1>
                </div>
                <div className='creation-content'>
                    <div className='creation-meta'>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.title') }
                                value={item.title}
                                disabled
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.original_title') }
                                value={item.originalTitle}
                                disabled
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.year') }
                                value={item.year}
                                disabled
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.producer') }
                                value={item.producer}
                                disabled
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.country') }
                                value={item.country}
                                disabled
                            />
                        </div>
                        <div className='creation-meta--item creation-meta--item-tvshow'>
                            {
                                item.season ?
                                <Input
                                    label={ t('creation.season') }
                                    value={item.season}
                                    disabled
                                />
                                :
                                <></>
                            }
                            {
                                item.episode ?
                                <Input
                                    label={ t('creation.episode') }
                                    value={item.episode}
                                    disabled
                                />
                                :
                                <></>
                            }
                            {
                                item.episodeTitle ?
                                <Input
                                    label={ t('creation.episode_title') }
                                    value={item.episodeTitle}
                                    disabled
                                />
                                :
                                <></>
                            }
                        </div>
                        <div className='creation-meta--item'>
                            <h4>{ t('creations.duration') }</h4>
                            <p>
                                {
                                    `${padWithZero(secondsToHms(item.duration).h, 2)}:${padWithZero(secondsToHms(item.duration).m, 2)}:${padWithZero(secondsToHms(item.duration).s, 2)}`
                                }
                            </p>
                        </div>
                        <div className='creation-meta--item'>
                            <h4>{ t('creations.type') }</h4>
                            {
                                t(types.find(x => x.id === item?.type)?.name)
                            }
                        </div>
                    </div>
                
                    <UserAuthors
                        data={data.getCreation.authors}
                        onRefresh={() => refetch()}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserCreationScreen