import React from 'react'
import ActivityIndicator from './ActivityIndicator'

const Button = ({
  onClick,
  label,
  disabled,
  className,
  loading,
  icon,
  download,
  link,
}) => {

  if (download && link) {
    return (
      <a
        className={`btn${className ? ' ' + className : ''}${loading ? ' ' + 'btn--loading' : ''}${icon ? ' ' + 'btn--has-icon' : ''}`}
        href={link}
        download={download}
        disabled={disabled || loading}
        onClick={onClick}
      >
        {
          icon ?
          <div className='btn--icon'>{ icon }</div>
          :
          <></>
        }
        <span>{ label }</span>
        {
          loading ?
          <ActivityIndicator />
          :
          <></>
        }
      </a>
    )
  }

  return (
    <button
      className={`btn${className ? ' ' + className : ''}${loading ? ' ' + 'btn--loading' : ''}${icon ? ' ' + 'btn--has-icon' : ''}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {
        icon ?
        <div className='btn--icon'>{ icon }</div>
        :
        <></>
      }
      <span>{ label }</span>
      {
        loading ?
        <ActivityIndicator />
        :
        <></>
      }
    </button>
  )
}

export default Button