import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GetAuthorReports, GetReport, GetViewFilters } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Tasks from '../../components/Tasks'
import { ReportTypes, TaskTypes } from '../../util/const'
import CreateReportModal from '../../components/CreateReportModal'
import { formatDate } from '../../util/format'
import ReportFilters from '../../components/ReportFilters'
import { capitalizeName } from '../../util/string'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'creation',
        label: 'Teos',
    },
    {
        value: 'role',
        label: 'Roll',
    },
    {
        value: 'views',
        label: 'Vaatamised',
    },
    {
        value: 'total',
        label: 'Bruto summa',
    },
]

const AuthorReportsScreen = () => {
    const { t } = useTranslation()
    const { reportId, authorName } = useParams()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [showModal, setShowModal] = useState(false)
    const [filters, setFilters] = useState(initialFilterState)
    const [extraFilters, setExtraFilters] = useState()

    const { data: reportData } = useQuery(GetReport, { variables: { id: parseInt(reportId) } })

    useQuery(GetViewFilters, {
        onCompleted: (data) => {
            if (data?.getViewFilters) {
                const yearList = data.getViewFilters.years.map(x => ({
                    value: x,
                    label: x,
                }))

                const distList = data.getViewFilters.distributions.map(x => ({
                    value: x,
                    label: x,
                }))

                setFilters({
                    ...filters,
                    distributions: distList,
                    years: yearList,
                })
            }
        },
    })

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'total':
                return `${parseFloat(row[field]).toFixed(2)} €`
            case 'createdAt':
                return formatDate(row[field])
            case 'creation':
                return getCreationLabel(row)
            case 'role':
                return row?.role?.name ? t(row.role.name) : ''
            default:
                return row[field]
        }
    }

    const getCreationLabel = (row) => {
        let label = ''
        if (row?.creation?.title) label = capitalizeName(row.creation.title)
        if (row?.creation?.year) label += ` ${row.creation.year}`
        if (row?.creation?.episode) label += ` - ${row.creation.episode}`
        return label
    }

    const handleSuccess = () => {
        tableRef.current?.refresh()
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    return (
        <div className='author-reports'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ `${capitalizeName(authorName)}` }</h1>
                    {
                        reportData?.getReport ?
                        <h4>{ `${reportData.getReport.type} ${reportData.getReport.year}` }</h4>
                        :
                        <></>
                    }
                </div>
                <Tasks
                    type={TaskTypes.Report}
                />
                <ReportFilters
                    ref={filtersRef}
                    onFilterChange={handleFilterChange}
                    allowed={{
                        query: true,
                    }}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetAuthorReports}
                        fieldConditions={fieldConditions}
                        queryKey={'getAuthorReports'}
                        queryOptions={{
                            fetchPolicy: 'no-cache',
                            variables: {
                                reportId: parseInt(reportId),
                                authorName,
                            },
                        }}
                        hideInputs={true}
                        initialOrderBy={'total'}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
                <CreateReportModal
                    show={showModal}
                    filters={filters}
                    close={() => setShowModal(false)}
                    onSuccess={handleSuccess}
                />
            </div>
        </div>
    )
}

export default AuthorReportsScreen