import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetBalances } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import BalanceFilters from '../../components/BalanceFilters'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'pid',
        label: 'Isikukood',
    },
    {
        value: 'payoutSum',
        label: 'Väljamaksed',
    },
    {
        value: 'bonusSum',
        label: 'Boonused',
    },
    {
        value: 'distSum',
        label: 'Jaotus',
    },
    {
        value: 'foreignSum',
        label: 'Välisjaotus',
    },
    {
        value: 'total',
        label: 'Saldo',
    },
]

const BalancesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [extraFilters, setExtraFilters] = useState()

    const fieldConditions = (row, field) => {
        if (['foreignSum', 'bonusSum', 'distSum', 'payoutSum'].includes(field)) return row[field] ? `${parseFloat(row[field]).toFixed(2)}€` : '0€'
        if (field === 'total') return row[field] ? `${parseFloat(row[field]).toFixed(2)}€` : '0€'
        return row[field]
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    return (
        <div className='balances'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('balances.title') }</h1>
                </div>

                <div className='below-title'>
                    <BalanceFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                    />
                </div>

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetBalances}
                        queryKey={'getBalances'}
                        hideInputs={true}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'date'}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default BalancesScreen