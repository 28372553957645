import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import Button from './Button'
import Modal from './Modal'
import { RemoveDistribution, RemoveReport, UpdateReport } from '../graphql/mutations'
import SelectInput from './SelectInput'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'
import { GetReportFilters, GetReports } from '../graphql/queries'
import { ReportTypes } from '../util/const'
import EmptyList from './EmptyList'
import ConfirmModal from './ConfirmModal'

const initialState = {
    year: '',
    type: '',
    country: '',
}

const initialErrorsState = {
    year: null,
    type: null,
    country: null,
    budget: null,
    generic: null,
}

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const initialLoading = {
    report: null,
    distribution: null,
}

const ManageReportsModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [filters, setFilters] = useState(initialFilterState)
    const [loading, setLoading] = useState(initialLoading)
    const [selectedForDelete, setSelectedForDelete] = useState(initialLoading)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [success, setSuccess] = useState(null)
    const [removeReport] = useMutation(RemoveReport)
    const [updateReport] = useMutation(UpdateReport)
    const [removeDistribution] = useMutation(RemoveDistribution)

    const { data: reportsData } = useQuery(GetReports, {
        fetchPolicy: 'no-cache',
        variables: {
            year: item.year,
            type: item.type,
        },
    })

    useQuery(GetReportFilters, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            let yearList, distList

            if (data?.getReportFilters) {
                yearList = data.getReportFilters.years.map(x => ({
                    value: x,
                    label: x,
                }))
            }


            if (data?.getReportFilters?.distributions) {
                distList = data.getReportFilters.distributions.map(x => ({
                    value: x,
                    label: x,
                }))
            }

            setFilters({
                ...filters,
                distributions: distList,
                years: yearList,
            })
        },
    })

    const handleClose = () => {
        setErrors({ ...initialErrorsState })
        setItem({ ...initialState })
        setLoading({ ...initialLoading })

        if (close) close()
    }

    const handleRemoveReport = async (id) => {
        if (!id) return
        setLoading({
            ...loading,
            report: id,
        })

        try {
            const { data } = await removeReport({
                variables: {
                    id,
                },
                refetchQueries: ['getReports', 'getDistributionReports', 'getReportFilters'],
            })

            if (!data?.removeReport) {
                setErrors({
                    ...errors,
                    generic: t('reports.remove_error')
                })
                return
            }

            if (data.removeReport === 'SUCCESS') {
                setSuccess(`${t('reports.remove_success')}`)
            }
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('reports.remove_error')
            })
        } finally {
            setLoading({ ...initialLoading })
        }
    }

    const handleRemoveDistribution = async (reportId, distribution) => {
        if (!reportId || !distribution) return
        setLoading({
            report: reportId,
            distribution,
        })

        try {
            const { data } = await removeDistribution({
                variables: {
                    reportId,
                    distribution,
                },
                refetchQueries: ['getReports', 'getDistributionReports', 'getViewFilters'],
            })

            if (!data?.removeDistribution) {
                setErrors({
                    ...errors,
                    generic: t('reports.distribution_remove_error')
                })
                return
            }

            if (data.removeDistribution === 'SUCCESS') {
                setSuccess(`${t('reports.distribution_remove_success')}`)
            }
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('reports.distribution_remove_error')
            })
        } finally {
            setLoading({ ...initialLoading })
        }
    }

    const confirmDelete = () => {
        setShowConfirmModal(false)
        
        if (selectedForDelete.report && selectedForDelete.distribution) {
            handleRemoveDistribution(selectedForDelete.report, selectedForDelete.distribution)
            return
        }

        if (selectedForDelete.report) handleRemoveReport(selectedForDelete.report)
    }

    const getReportTitle = () => {
        if (selectedForDelete.report) {
            const report = reportsData?.getReports?.find(x => x.id === selectedForDelete.report)
            return report ? `${report.type} ${report.year}` : ''
        }

        return ''
    }

    const getDistributionTitle = () => {
        if (selectedForDelete.report && selectedForDelete.distribution) {
            const report = reportsData?.getReports?.find(x => x.id === selectedForDelete.report)
            return report ? `${report.type} ${report.year} - ${selectedForDelete.distribution}` : ''
        }

        return ''
    }

    const handleToggleReportVisibility = async (id, visible) => {
        const res = await updateReport({
            variables: {
                input: {
                    id,
                    visible,
                },
            },
            refetchQueries: ['getReports'],
        })
    }

    return (
        <>
            <Modal
                className={'modal-manage-reports'}
                show={show}
                close={handleClose}
                title={t('reports.manage_reports')}
            >
                <div className='modal-manage-reports-filters'>
                    <SelectInput
                        label={t('reports.year')}
                        options={filters.years}
                        value={filters.years.find(x => x.value === item.year)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setItem({ ...item, year: option.value })}
                        error={errors.year}
                    />
                    <SelectInput
                        label={t('reports.type')}
                        options={filters.types}
                        value={filters.types.find(x => x.value === item.type)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setItem({ ...item, type: option?.value })}
                        error={errors.type}
                        isClearable
                    />
                </div>

                {
                    reportsData?.getReports?.length ?
                        reportsData.getReports.map(report =>
                            <div key={report.id} className='report-item'>
                                <div className='report-item-header'>
                                    <div>
                                        {`${report.type} ${report.year}`}
                                    </div>
                                    <div className='report-item-actions'>
                                        <Button
                                            label={report?.visible ? t('reports.hide_report') : t('reports.show_report')}
                                            onClick={() => handleToggleReportVisibility(report.id, !report.visible)}
                                            className={`btn-small${report?.visible? ' btn-cancel' : ''}`}
                                        />
                                        <Button
                                            label={t('reports.delete_report')}
                                            onClick={() => {
                                                setSelectedForDelete({
                                                    report: report.id,
                                                    distribution: null,
                                                })
                                                setShowConfirmModal(true)
                                            }}
                                            className={'btn-small'}
                                            loading={loading.report === report.id}
                                        />
                                    </div>
                                </div>
                                <div className='report-item-content'>
                                    {
                                        report?.distributions?.length ?
                                            report.distributions.map((dist, i) =>
                                                <div key={`${dist.name}-${i}`} className='report-item-content-item'>
                                                    <span>{dist.name}</span>
                                                    <Button
                                                        label={t('reports.delete_distribution')}
                                                        className={'btn-small'}
                                                        onClick={() => {
                                                            setSelectedForDelete({
                                                                distribution: dist.name,
                                                                report: report.id,
                                                            })
                                                            setShowConfirmModal(true)
                                                        }}
                                                        loading={loading.report === report.id && loading.distribution === dist.name}
                                                    />
                                                </div>
                                            )
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        )
                        :
                        <EmptyList />
                }

                <ErrorMessage message={errors?.generic || errors?.budget} />
                <SuccessMessage message={success} />

                <Button
                    label={t('reports.close')}
                    onClick={close}
                    className={'btn-cancel'}
                />
            </Modal>
            <ConfirmModal
                show={showConfirmModal}
                close={() => setShowConfirmModal(false)}
                confirmLabel={t('reports.confirm_delete')}
                title={selectedForDelete?.distribution ? t('reports.confrim_distribution_delete', { distribution: getDistributionTitle() }) : t('reports.confirm_report_delete', { report: getReportTitle() })}
                onConfirm={confirmDelete}
            />
        </>
    )
}

export default ManageReportsModal