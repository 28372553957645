import gql from 'graphql-tag'

export const GetSIDStatus = gql`
  query getSIDStatus ($pid: String!) {
    getSIDStatus (pid: $pid) {
      status
      token
    }
  }
`

export const GetMIDStatus = gql`
  query getMIDStatus ($pid: String!) {
    getMIDStatus (pid: $pid) {
      status
      token
    }
  }
`

export const IdAuth = gql`
  query idAuth {
    idAuth {
      error
      nonce
    }
  }
`

export const GetUsers = gql`
  query getUsers ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getUsers (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        pseudonym
        role
        iban
        ipi
        pid
        email
        address
        phone
        dead
      }
    }
  }
`

export const GetUser = gql`
  query getUser ($id: Int!) {
    getUser (id: $id) {
      id
      name
      altName
      altIpi
      pseudonym
      pseudoIpi
      role
      iban
      ipi
      ipiBase
      pid
      email
      address
      phone
      dead
      comment
      heirs {
        id
        percentage
        heir {
          id
          name
        }
      }
      authors {
        id
        percentage
        author {
          id
          name
        }
      }
    }
  }
`

export const GetProfile = gql`
  query getProfile {
    getProfile {
      id
      name
      pseudonym
      role
      iban
      ipi
      pid
      email
      address
      phone
      dead
      heirs {
        id
        percentage
        heir {
          id
          name
        }
      }
      authors {
        id
        percentage
        author {
          id
          name
        }
      }
    }
  }
`

export const SearchUsers = gql`
  query searchUsers ($input: String!) {
    searchUsers (input: $input) {
      id
      name
      ipi
      pid
    }
  }
`

export const SearchSocieties = gql`
  query searchSocieties ($input: String!) {
    searchSocieties (input: $input) {
      id
      name
      code
    }
  }
`

export const GetCreations = gql`
  query getCreations ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getCreations (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        title
        originalTitle
        episodeTitle
        year
        producer
        country
        episode
        season
        createdAt
        updatedAt
        creationType {
          name
        }
      }
    }
  }
`

export const GetAuthorCreations = gql`
  query getAuthorCreations ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getAuthorCreations (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        title
        originalTitle
        episodeTitle
        year
        producer
        country
        episode
        season
        createdAt
        updatedAt
        creationType {
          name
        }
      }
    }
  }
`

export const GetCreation = gql`
  query getCreation ($id: Int!) {
    getCreation (id: $id) {
      id
      title
      originalTitle
      year
      producer
      country
      duration
      episode
      episodeTitle
      season
      type
      blacklisted
      idaRef

      creationType {
        id
        name
      }
      
      authors {
        id
        authorName
        ipi
        authorRole
        authorSociety
        
        role {
          name
        }
        user {
          id
        }
      }
    }
  }
`

export const GetCreationTypes = gql`
  query getCreationTypes {
    getCreationTypes {
      id
      name
    }
  }
`

export const GetAuthorRoles = gql`
  query getAuthorRoles {
    getAuthorRoles {
      id
      name
    }
  }
`

export const GetPoints = gql`
  query getPoints {
    getPoints {
      id
      points
      start
      end
      category
      type
    }
  }
`

export const GetBudgets = gql`
  query getBudgets {
    getBudgets {
      id
      year
      tvBudget
      vodBudget
      payed
    }
  }
`

export const GetSocieties = gql`
  query getSocieties ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getSocieties (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        code
        country
      }
    }
  }
`

export const GetSociety = gql`
  query getSociety ($id: Int!) {
    getSociety (id: $id) {
      id
      name
      country
      code

      roles {
        id

        role {
          name
        }
      }
      
      types {
        id

        type {
          name
        }
      }
    }
  }
`

export const GetViews = gql`
  query getViews ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getViews (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        type
        distribution
        year
        count
        creation {
          id
          title
          season
          episode
          year
        }
      }
    }
  }
`

export const GetViewFilters = gql`
  query getViewFilters {
    getViewFilters {
      years
      distributions
    }
  }
`

export const GetBonusFilters = gql`
  query getBonusFilters {
    getBonusFilters {
      years
    }
  }
`

export const GetForeignFilters = gql`
  query getForeignFilters {
    getForeignFilters {
      years
    }
  }
`

export const GetUnrecognized = gql`
  query getUnrecognized ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getUnrecognized (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        title
        originalTitle
        season
        episode
        year
        country
        producer
        blacklisted
      }
    }
  }
`

export const GetUnrecognizedItem = gql`
  query getUnrecognizedItem ($id: Int!) {
    getUnrecognizedItem (id: $id) {
      id
      title
      originalTitle
      season
      episode
      year
      country
      producer
      type
      duration
      distribution
      distributionYear
      distributionType
      distributionCountry
      count
      idaRef

      creationType {
        id
        name
      }
      
      authors {
        id
        authorName
        ipi
        authorRole
        authorSociety
        
        role {
          name
        }
      }
    }
  }
`

export const GetUnrecognizedFilters = gql`
  query getUnrecognizedFilters {
    getUnrecognizedFilters {
      years
      distributions
    }
  }
`

export const GetTasks = gql`
  query getTasks ($history: Boolean, $page: Int, $limit: Int, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getTasks (history: $history, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        type
        description
        creator
        createdAt
        result
        updatedAt
      }
    }
  }
`

export const GetCreationDistributions = gql`
  query getCreationDistributions {
    getCreationDistributions {
      id
      creationType
      role
      amount
    }
  }
`

export const GetDistributionReports = gql`
  query getDistributionReports ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getDistributionReports (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        name
        points
        country
        
        report {
          id
          type
          year
          createdAt
          pointValue
        }
      }
    }
  }
`

export const GetUserDistributionReports = gql`
  query getUserDistributionReports ($authorId: Int, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getUserDistributionReports (authorId: $authorId, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        name
        points
        country
        total
        
        report {
          id
          type
          year
          createdAt
          pointValue
        }
      }
    }
  }
`

export const GetAuthorsReports = gql`
  query getAuthorsReports ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getAuthorsReports (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        authorName
        points
        country
        createdAt
        total
        reportId
      }
    }
  }
`
export const GetAuthorReports = gql`
  query getAuthorReports ($reportId: Int!, $authorName: String!, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getAuthorReports (reportId: $reportId, authorName: $authorName, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        points
        total
        views

        creation {
          id
          title
          episode
          year
        }

        report {
          type
          year
        }

        role {
          name
        }
      }
    }
  }
`

export const GetUserReports = gql`
query getUserReports ($reportId: Int!, $authorName: String!, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
  getUserReports (reportId: $reportId, authorName: $authorName, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        points
        total
        views

        creation {
          id
          title
          episode
          year
        }

        report {
          type
          year
        }

        role {
          name
        }
      }
    }
  }
`

export const GetDistributions = gql`
  query getDistributions ($reportId: Int!, $name: String!, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getDistributions (reportId: $reportId, name: $name, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        authorName
        points
        createdAt
        total
        
        report {
          id
          type
          year
          createdAt
          pointValue
        }

        user {
          id
        }

        namedUser {
          id
        }
      }
    }
  }
`

export const GetReportFilters = gql`
  query getReportFilters {
    getReportFilters {
      years
      distributions
      societies {
        id
        code
        name
      }
    }
  }
`
export const GetReport = gql`
  query getReport ($id: Int!) {
    getReport (id: $id) {
      id
      type
      year
      createdAt
    }
  }
`

export const GetReports = gql`
  query getReports ($year: Int!, $type: String) {
    getReports (year: $year, type: $type) {
      id
      type
      year
      visible
      createdAt
      distributions {
        id
        name
      }
    }
  }
`

export const GetAuthorDistributions = gql`
  query getAuthorDistributions ($reportId: Int!, $distribution: String!, $authorName: String!, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getAuthorDistributions (reportId: $reportId, distribution: $distribution, authorName: $authorName, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        authorName
        points
        createdAt
        views
        total

        report {
          id
          type
          year
          createdAt
          pointValue
        }

        creation {
          id
          title
          episode
          season
          year
        }

        role {
          name
        }
      }
    }
  }
`

export const GetUserDistributions = gql`
  query getUserDistributions ($reportId: Int!, $distribution: String!, $authorId: Int, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getUserDistributions (reportId: $reportId, distribution: $distribution, authorId: $authorId, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        authorName
        points
        createdAt
        views
        total

        report {
          id
          type
          year
          createdAt
          pointValue
        }

        creation {
          id
          title
          episode
          episodeTitle
          season
          year
        }

        role {
          name
        }
      }
    }
  }
`

export const GetBonuses = gql`
  query getBonuses ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getBonuses (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        label
        description
        sum
        date
        pid
      }
    }
  }
`

export const GetUserBonuses = gql`
  query getUserBonuses ($authorId: Int, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getUserBonuses (authorId: $authorId, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        label
        description
        sum
        date
        pid
      }
    }
  }
`

export const GetForeign = gql`
  query getForeign ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getForeign (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        pid
        ipi
        type
        creation
        channel
        country
        society
        regno
        currency
        sum
        total
        tax
        fee
      }
    }
  }
`

export const GetPayouts = gql`
  query getPayouts ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getPayouts (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        pid
        ipi
        type
        channel
        country
        society
        regno
        currency
        sum
        total
        tax
        fee
        date
      }
    }
  }
`

export const GetUserForeign = gql`
  query getUserForeign ($authorId: Int, $page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getUserForeign (authorId: $authorId, page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        name
        pid
        ipi
        type
        creation
        channel
        country
        society
        regno
        currency
        sum
        total
        tax
        fee
      }
    }
  }
`

export const GetReportExcel = gql`
  query getReportExcel ($year: Int!, $type: String!, $distribution: String, $foreign: Boolean, $society: Int, $bonus: Boolean) {
    getReportExcel (year: $year, type: $type, distribution: $distribution, foreign: $foreign, society: $society, bonus: $bonus)
  }
`

export const GetSocietyReportExcel = gql`
  query getSocietyReportExcel ($year: Int!, $society: Int!) {
    getSocietyReportExcel (year: $year, society: $society)
  }
`

export const GetBonusExcel = gql`
  query getBonusExcel ($year: Int!) {
    getBonusExcel (year: $year)
  }
`

export const GetForeignExcel = gql`
  query getForeignExcel ($year: Int!) {
    getForeignExcel (year: $year)
  }
`

export const GetDistributionTotal = gql`
  query getDistributionTotal ($year: Int!, $type: String!, $distribution: String, $author: String) {
    getDistributionTotal (year: $year, type: $type, distribution: $distribution, author: $author) {
      sum
      payed
    }
  }
`

export const GetUserDistributionTotal = gql`
  query getUserDistributionTotal ($authorId: Int, $year: Int!, $type: String!, $distribution: String) {
    getUserDistributionTotal (authorId: $authorId, year: $year, type: $type, distribution: $distribution) {
      sum
      payed
    }
  }
`

export const GetBonusTotal = gql`
  query getBonusTotal ($year: Int, $query: String) {
    getBonusTotal (year: $year, query: $query) {
      sum
      payed
    }
  }
`

export const GetUserBonusTotal = gql`
  query getUserBonusTotal ($authorId: Int, $year: Int, $query: String) {
    getUserBonusTotal (authorId: $authorId, year: $year, query: $query) {
      sum
      payed
    }
  }
`

export const GetForeignTotal = gql`
  query getForeignTotal ($year: Int, $query: String) {
    getForeignTotal (year: $year, query: $query) {
      sum
      payed
    }
  }
`

export const GetUserForeignTotal = gql`
  query getUserForeignTotal ($authorId: Int, $year: Int, $query: String) {
    getUserForeignTotal (authorId: $authorId, year: $year, query: $query) {
      sum
      payed
    }
  }
`

export const GetIncomeTaxStatement = gql`
  query getIncomeTaxStatement ($year: Int!, $pid: String) {
    getIncomeTaxStatement (year: $year, pid: $pid) {
      name
      items {
        country
        regno
        society
        type
        currency
        tax
        total
        date
      }
    }
  }
`
export const GetPayoutYears = gql`
  query getPayoutYears ($pid: String) {
    getPayoutYears (pid: $pid)
  }
`

export const GetFixRequests = gql`
  query getFixRequests ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getFixRequests (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        id
        status
        createdAt
        request {
          creationId
          authorId
          authorRole
          authorName
          creationTitle
          creationEpisode
          creationEpisodeTitle
          creationSeason
          creationYear
          creationRoles
        }
        type
        user {
          id
          name
          ipi
        }
      }
    }
  }
`

export const GetCreationAuthor = gql`
  query getCreationAuthor ($id: Int!) {
    getCreationAuthor (id: $id) {
      id
      authorName
      authorRole
    }
  }
`

export const GetUnrecognizedExcel = gql`
  query getUnrecognizedExcel {
    getUnrecognizedExcel
  }
`

export const GetBalances = gql`
  query getBalances ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
    getBalances (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
      count
      rows {
        bonusSum
        payoutSum
        distSum
        foreignSum
        total
        pid
        name
      }
    }
  }
`

export const GetUserBalance = gql`
  query getUserBalance ($year: Int!) {
    getUserBalance (year: $year) {
      bonusSum
      payoutSum
      distSum
      foreignSum
      total
    }
  }
`