import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GetTasks } from '../graphql/queries'
import { formatDate } from '../util/format'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { StopTask } from '../graphql/mutations'

const Tasks = ({
    type,
}) => {

    const { t } = useTranslation()
    const { data: tasksData, startPolling, stopPolling } = useQuery(GetTasks, {
        fetchPolicy: 'no-cache',
    })
    const [stopTask] = useMutation(StopTask)

    useEffect(() => {
        startPolling(2000)

        return () => {
            stopPolling()
        }
    }, [])

    const handleStopTask = async (id) => {
        try {
            await stopTask({
                variables: {
                    id: parseInt(id),
                },
                refetchQueries: ['getTasks'],
            })
        } catch (err) {
            console.log('handleStopTask: ', err)
        }
    }

    return (
        tasksData?.getTasks?.rows?.length > 0 ?
        <div className='tasks'>
            <h5>{ t('tasks.title') }</h5>
            <div className='tasks-list'>
            {
                tasksData.getTasks.rows.map(x =>
                    <div key={`tasks-${x.id}`} className='tasks-item'>
                        <span>{ t(x.type) }</span>
                        <span>{ x.description }</span>
                        <span>{ `${t('tasks.start')}: ${formatDate(x.createdAt, true)}` }</span>
                        <Button
                                onClick={() => handleStopTask(x.id)}
                                label={t('tasks.stop')}
                            />
                    </div>    
                )
            }
            </div>
        </div>
        :
        <></>
    )
}

export default Tasks