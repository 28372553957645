import React from 'react'
import { useTranslation } from 'react-i18next'

const Total = ({
    total,
    hidePayed,
    sumLabel,
}) => {

    const { t } = useTranslation()

    if (!total) return <></>

    return (
        <div className='total-sum'>
            <div>{ `${sumLabel ? sumLabel : t('total_sum')}: ${total.sum}€` }</div>
            {
                hidePayed ?
                <></>
                :
                <div>{ `${t('payed')}: ${total.payed ? t('yes') : t('no')}` }</div>
            }
        </div>
    )
}

export default Total