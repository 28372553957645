import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { capitalizeName } from '../util/string'
import Button from './Button'
import Checkbox from './Checkbox'
import PlusIcon from './icons/plus'
import SelectInput from './SelectInput'
import { GetAuthorRoles } from '../graphql/queries'
import { CreateFixRequest } from '../graphql/mutations'
import { useParams } from 'react-router-dom'
import Input from './Input'
import { FixType } from '../util/const'

const authorState = {
    authorName: null,
    authorRole: null,
    ipi: null,
    authorSociety: null,
}

const initialErrorsState = {
    authorName: null,
    ipi: null,
    authorSociety: null,
    authorRole: null,
}

const UserAuthors = ({
    data,
}) => {
    const { t } = useTranslation()
    const { id } = useParams()

    const [items, setItems] = useState(data)
    const [roles, setRoles] = useState([])
    const [errors, setErrors] = useState(initialErrorsState)

    const [newAuthor, setNewAuthor] = useState(authorState)
    const [createFix] = useMutation(CreateFixRequest)

    const [expandedAuthor, setExpandedAuthor] = useState(false)
    const [selectedRole, setSelectedRole] = useState(null)
    const [remove, setRemove] = useState(false)
    const [saved, setSaved] = useState(false)
    const [added, setAdded] = useState(false)

    useQuery(GetAuthorRoles, {
        onCompleted: (data) => {
            if (!data?.getAuthorRoles) return
            setRoles(data.getAuthorRoles)
        },
    })

    useEffect(() => {
        setItems(data)
    }, [data])

    useEffect(() => {
        setSelectedRole(null)
        setRemove(false)
        setSaved(false)
        setAdded(false)
    }, [expandedAuthor])

    const handleAddFix = async (fix, type) => {
        try {
            const res = await createFix({
                variables: {
                    request: JSON.stringify(fix),
                    type,
                },
            })

            if (res?.data?.createFixRequest === 'SUCCESS') {
                return true
            }

            return false
        } catch (e) {
            console.log(e)
            return false
        }
    }

    const createAuthorFix = async () => {
        if (!expandedAuthor || (!remove && !selectedRole)) return

        const isSent = await handleAddFix({
            authorId: expandedAuthor,
            creationId: id,
            authorRole: selectedRole?.id,
        }, (remove ? FixType.RemoveAuthor : FixType.UpdateAuthor))

        if (!isSent) return

        setSaved(true)
    }

    const createNewAuthor = async () => {
        if (added || hasInputErrors() || !newAuthor.authorName || !newAuthor.authorRole) return

        const isFixed = await handleAddFix({
            authorName: newAuthor.authorName,
            authorRole: newAuthor.authorRole,
            creationId: id,
        }, FixType.AddAuthor)

        if (!isFixed) return

        setAdded(true)

        setTimeout(() => {
            setAdded(false)
            setNewAuthor({...authorState})
        }, 3000)
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!newAuthor.authorName) {
            hasErrors = true
            errorState.authorName = t('reports.fill_field')
        }

        if (!newAuthor.authorRole) {
            hasErrors = true
            errorState.authorRole = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setNewAuthor({
            ...newAuthor,
            [field]: value,
        })
    }

    return (
        <div className='creation-authors'>
            <h3>{ t('creation.authors') }</h3>
            {
                items ?
                items.map((author, index) =>
                    <div
                        key={author.id}
                        className={`creation-authors--item`}
                    >
                        <div className='creation-authors--item-info'>
                            <div className='creation-authors--item-name'>
                                { capitalizeName(author.authorName) }
                            </div>
                            <div className='creation-authors--item-role'>
                                { t(author.role.name) }
                            </div>
                            {
                                expandedAuthor === author.id ?
                                <div className='creation-authors--item-fix'>
                                    <div className='creation-authors--item-fix-fields'>
                                        <SelectInput
                                            placeholder={t('creation.author_role')}
                                            options={roles?.filter(i => i.id !== author.authorRole)}
                                            value={selectedRole}
                                            getOptionLabel={(option) => t(option.name)}
                                            getOptionValue={(option) => option.id}
                                            onChange={(option) => setSelectedRole(option)}
                                            error={errors.authorRole}
                                        />
                                        <Checkbox
                                            label={`${t('creation.remove_author')}`}
                                            value={remove}
                                            onChange={(e) => setRemove(e.target.checked)}
                                        />
                                    </div>
                                    <Button
                                        className={'btn-small'}
                                        label={saved ? t('creation.fix_saved') : t('creation.save_fix')}
                                        onClick={createAuthorFix}
                                        disabled={saved}
                                    />
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <Button
                            className={'btn-small'}
                            label={t('creation.fix_author')}
                            onClick={() => setExpandedAuthor(expandedAuthor === author.id ? null : author.id)}
                        />
                    </div>
                )
                :
                <p>{ t('creation.no_authors') }</p>
            }
            <div className='creation-authors--add'>
                <h4>{ t('creation.add_author') }</h4>
                <Input
                    placeholder={t('creation.author_name')}
                    value={newAuthor.authorName}
                    onChange={(e) => setField('authorName', e.target.value)}
                    error={errors.authorName}
                    disabled={added}
                />
                <SelectInput
                    placeholder={t('creation.author_role')}
                    options={roles}
                    value={roles.find(x => x.id === newAuthor?.authorRole) ?? null}
                    getOptionLabel={(option) => t(option.name)}
                    getOptionValue={(option) => option.id}
                    onChange={(option) => setField('authorRole', option.id)}
                    error={errors.authorRole}
                    disabled={added}
                />
                <Button
                    label={t('fixes.add_author')}
                    onClick={createNewAuthor}
                    icon={<PlusIcon />}
                    disabled={added}
                />
                {
                    added ?
                    <p>{ t('fixes.author_added') }</p>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default UserAuthors