import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import Input from './Input'
import { useAuth } from '../providers/Auth'
import { useQuery, useMutation } from '@apollo/client'
import { GetSIDStatus } from '../graphql/queries'
import { LoginSID } from '../graphql/mutations'

const SmartID = ({
    onCancel,
}) => {

    const { t } = useTranslation()
    const { user, signIn } = useAuth()
    const navigate = useNavigate()
    const poll = useRef()
    const [pid, setPid] = useState()
    const [signInWithSID] = useMutation(LoginSID, {
        fetchPolicy: 'no-cache',
    })
    const [verificationCode, setVerificationCode] = useState()
    const [retries, setRetries] = useState(0)
    const [error, setError] = useState()
    
    const { refetch: getSIDStatus } = useQuery(GetSIDStatus, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (user) {
            navigate('/', {
                replace: true,
            })
        }

        return () => {
            reset()
        }
    }, [])

    const reset = () => {
        setError(null)
        setRetries(0)
        poll.current = null
        setVerificationCode(null)
    }

    const login = async () => {
        const { data } = await signInWithSID({
            variables: {
                pid,
            },
        })

        if (data?.loginWithSmartId?.error) {
            setError(t('login.error'))
            return
        }

        if (data?.loginWithSmartId?.code) {
            setVerificationCode(data.loginWithSmartId.code)
            startPoll()
        }
    }

    const startPoll = async () => {
        poll.current = setInterval(async () => {

            if (retries > 10) {
                clearInterval(poll.current)
                return
            }

            try {
                const { data } = await getSIDStatus({
                    pid,
                })
    
                if (!['SUCCESS', 'PENDING'].includes(data.getSIDStatus.status)) {
                    clearInterval(poll.current)
                    setError(t('login.error'))
                    return
                }
    
                if (data.getSIDStatus?.token) {
                    signIn(data.getSIDStatus.token)
                    clearInterval(poll.current)
                }
            } catch (err) {
                clearInterval(poll.current)
                setError(t('login.error'))
            }

            setRetries(retries + 1)
        }, 3000)
    }

    const handleCancel = () => {
        if (onCancel) onCancel()
    }

    const handleEnter = ({ key }) => {
        if (key === 'Enter') login()
    }

    return (
        <div className='login-method login-method--smartid'>
            <div className='login-method--logo'>
                <img src='./assets/logos/smart-id.svg' />
            </div>
            {
                !error ?
                <>
                    {
                        verificationCode ?
                        <div>
                            <div className='verification-code'>
                                <p>{ t('login.verification_code')}</p>
                                <span>{ verificationCode }</span>
                            </div>
                        </div>
                        :
                        <div className='login-method--input'>
                            <Input
                                label={t('login.personal_number')}
                                value={pid}
                                onChange={(e) => setPid(e.target.value)}
                                onKeyUp={handleEnter}
                                placeholder={t('login.enter_personal_number')}
                                autoComplete={'new-password'}
                            />
                            <div className='login-actions'>
                                <Button
                                    className={'btn-cancel'}
                                    label={t('login.cancel')}
                                    onClick={handleCancel}
                                />
                                <Button
                                    label={t('login.continue')}
                                    onClick={login}
                                    disabled={!pid || pid.length < 11}
                                />
                            </div>
                        </div>
                    }
                </>
                :
                <div className='login-method--error'>
                    <div className='login-method--error-message'>
                        <p>{ error }</p>
                    </div>
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.back')}
                            onClick={reset}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default SmartID