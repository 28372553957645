import { t } from 'i18next'
import React from 'react'
import ChevronIcon from './icons/chevron'

const MaxPages = 10

const Pagination = ({ total, onNext, onPrev, onPageClick, current, limit }) => {

  const getTotalPages = () => {
    return Math.ceil(total / limit)
  }

  const handlePageClick = (page) => {
    if (page === null || page === undefined || !onPageClick || page === current + 1) return

    onPageClick(page - 1)
  }

  const displayNumberPagination = () => {
    const total = getTotalPages()

    const minPage = current - 5 < 1 ? 0 : current - 5
    const maxPage = minPage + MaxPages < total ? minPage + MaxPages : total

    const pages = []

    for (let i = minPage; i < maxPage; i++) {
      pages.push(i)
    }

    return pages.map(page =>
      <span
        className={`pagination-pages-page${page === current ? ' pagination-pages-page--current' : ''}`}
        key={`page-${page}`}
        onClick={() => handlePageClick(page + 1)}
      >
        { `${page + 1}` }
      </span>
    )
  }

  if (!total || total <= 0) return <></>

  return (
    <div className="pagination">
      <div className={`pagination-prev ${current + 1 <= 1 ? 'disabled' : ''}`} onClick={onPrev}>
        <ChevronIcon />
        <span>{ t('Eelmised') }</span>
      </div>
      <div className="pagination-pages">
        <span className='pagination-pages-list'>
          { displayNumberPagination() }   
        </span>
        <span className="pagination-separator">/</span>
        <span className='pagination-max' onClick={() => handlePageClick(getTotalPages())}>{ `${getTotalPages()}` }</span>
      </div>
      <div className={`pagination-next ${current + 1 >= getTotalPages() ? 'disabled' : ''}`} onClick={onNext}>
        <span>{ t('Järgmised') }</span>
        <ChevronIcon />
      </div>
    </div>
  )
}

export default Pagination