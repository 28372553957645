import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { CreateFixRequest } from '../graphql/mutations'
import { GetAuthorRoles } from '../graphql/queries'
import SelectInput from './SelectInput'
import SuccessMessage from './SuccessMessage'
import { FixType } from '../util/const'
import ErrorMessage from './ErrorMessage'

const initialState = {
    title: '',
    year: '',
    season: '',
    episode: '',
    episodeTitle: '',
    roles: [],
}

const initialErrorsState = {
    title: null,
    year: null,
    season: null,
    episode: null,
    generic: null,
}

const AddUserCreationModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [roles, setRoles] = useState([])
    const [message, setMessage] = useState(null)

    const [createFix] = useMutation(CreateFixRequest)

    useQuery(GetAuthorRoles, {
        onCompleted: (data) => {
            if (!data?.getAuthorRoles) return
            setRoles(data.getAuthorRoles)
        },
    })

    const handleAdd = async () => {
        if (hasInputErrors()) return
        try {
            const res = await createFix({
                variables: {
                    request: JSON.stringify({
                        creationTitle: item.title,
                        creationYear: item.year,
                        creationSeason: item.season,
                        creationEpisode: item.episode,
                        creationEpisodeTitle: item.episodeTitle,
                        creationRoles: item.roles.map(i => i.id),
                    }),
                    type: FixType.AddCreation,
                },
            })

            if (res?.data?.createFixRequest !== 'SUCCESS') {
                setErrors({
                    ...initialErrorsState,
                    generic: t('fixes.add_creation_error'),
                })
                return
            }

            setMessage(t('fixes.creation_added'))
            setItem(initialState)

            // if (onSuccess) onSuccess(res.data.addCreation)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('fixes.add_creation_error'),
            })
        }
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.roles || item.roles.length === 0) {
            hasErrors = true
            errorState.roles = t('reports.fill_field')
        }

        if (!item.title) {
            hasErrors = true
            errorState.title = t('reports.fill_field')
        }

        if (!item.year) {
            hasErrors = true
            errorState.year = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    return (
        <Modal
            className={'modal-creation modal-user-creation'}
            show={show}
            close={handleClose}
            title={ t('creations.add_new') }
        >
            <Input
                label={ `${t('creations.creation_title')}*` }
                value={item.title}
                onChange={(e) => setItem({ ...item, title: e.target.value })}
                error={errors.title}
            />
            <Input
                label={ t('creations.season') }
                value={item.season}
                onChange={(e) => setItem({ ...item, season: e.target.value })}
            />
            <Input
                label={ t('creations.episode') }
                value={item.episode}
                onChange={(e) => setItem({ ...item, episode: e.target.value })}
            />
            <Input
                label={ t('creations.episode_title') }
                value={item.episodeTitle}
                onChange={(e) => setItem({ ...item, episodeTitle: e.target.value })}
            />
            <Input
                label={ `${t('creations.year')}*` }
                value={item.year}
                onChange={(e) => setItem({ ...item, year: e.target.value })}
                type={'number'}
                error={errors.year}
            />
            <SelectInput
                label={`${t('fixes.roles')}*`}
                placeholder={t('fixes.roles_placeholder')}
                options={roles}
                value={item.roles}
                getOptionLabel={(option) => t(option.name)}
                getOptionValue={(option) => option.id}
                onChange={(options) => setItem({...item, roles: options})}
                error={errors.roles}
                isMulti
            />
            
            <ErrorMessage message={errors.generic} />
            <SuccessMessage message={message} />

            <Button
                label={ t('creations.add_new') }
                onClick={handleAdd}
            />
        </Modal>
    )
}

export default AddUserCreationModal