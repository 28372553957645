import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { AddCreation, AddCreationAuthor } from '../graphql/mutations'
import { GetAuthorRoles, GetCreationTypes } from '../graphql/queries'
import SelectInput from './SelectInput'
import DurationInput from './DurationInput'
import { CreationTypes } from '../util/const'
import { capitalizeName } from '../util/string'

const initialState = {
    title: '',
    originalTitle: '',
    episodeTitle: '',
    country: '',
    year: '',
    producer: '',
    season: null,
    episode: null,
    duration: '',
    type: '',
}

const initialErrorsState = {
    title: null,
    originalTitle: null,
    country: null,
    year: null,
    producer: null,
    season: null,
    episode: null,
    duration: null,
    type: null,
    generic: null,
}

const AddCreationModal = ({
    show,
    close,
    onSuccess,
    initialData,
    authors,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [add] = useMutation(AddCreation)
    const [addAuthor] = useMutation(AddCreationAuthor)
    const [errors, setErrors] = useState(initialErrorsState)

    const { data: typeData } = useQuery(GetCreationTypes)

    const { data: roleData } = useQuery(GetAuthorRoles)

    useEffect(() => {
        if (!initialData?.title) {
            setItem(initialState)
            return
        }

        setItem(initialData)
    }, [initialData])

    const handleAdd = async () => {
        if (hasInputErrors()) return
        try {
            const data = {
                ...item,
                year: parseInt(item.year),
                duration: item.duration ? parseInt(item.duration) : undefined,
                season: item.season ? parseInt(item.season) : undefined,
                episode: item.episode ? parseInt(item.episode) : undefined,
                type: item.type ? parseInt(item.type) : undefined,
            }

            const res = await add({
                variables: {
                    data,
                },
            })

            if (!res?.data?.addCreation || res.data.addCreation === 'FAIL') {
                setErrors({
                    ...initialErrorsState,
                    generic: t('creations.add_error'),
                })
                return
            }

            if (res.data.addCreation === 'EXISTS') {
                setErrors({
                    ...initialErrorsState,
                    generic: t('creations.existing_error'),
                })
                return
            }

            if (authors?.length) {
                await Promise.all(authors.map(async (author) => {
                    await handleAddAuthor(res.data.addCreation, author)
                }))
            }

            if (onSuccess) onSuccess(res.data.addCreation)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('creations.add_error'),
            })
            console.log('handleAdd:', err)
        }
    }

    const handleAddAuthor = async (id, author) => {
        try {
            await addAuthor({
                variables: {
                    data: {
                        ...author,
                        creationId: parseInt(id),
                    },
                },
            })
        } catch (err) {
            console.log('addAuthor: ', err)
        }
    }

    const setDuration = (seconds) => {
        setItem({
            ...item,
            duration: seconds,
        })
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.title) {
            hasErrors = true
            errorState.title = t('reports.fill_field')
        }

        if (!item.originalTitle) {
            hasErrors = true
            errorState.originalTitle = t('reports.fill_field')
        }

        if (!item.year) {
            hasErrors = true
            errorState.year = t('reports.fill_field')
        }

        if (!item.type) {
            hasErrors = true
            errorState.type = t('reports.fill_field')
        }

        if (!item.duration) {
            hasErrors = true
            errorState.duration = t('reports.fill_field')
        }

        if (item.type === typeData.getCreationTypes.find(x => x.name === CreationTypes.Series)?.id && !item.episode) {
            hasErrors = true
            errorState.episode = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    return (
        <Modal
            className={'modal-creation'}
            show={show}
            close={handleClose}
            title={t('creations.add_new')}
        >
            <Input
                label={t('creations.creation_title')}
                value={item.title}
                onChange={(e) => setItem({ ...item, title: e.target.value })}
                error={errors.title}
            />
            <Input
                label={t('creations.original_title')}
                value={item.originalTitle}
                onChange={(e) => setItem({ ...item, originalTitle: e.target.value })}
                error={errors.originalTitle}
            />
            <Input
                label={t('creations.year')}
                value={item.year}
                onChange={(e) => setItem({ ...item, year: e.target.value })}
                type={'number'}
                error={errors.year}
            />
            <SelectInput
                label={t('creations.type')}
                options={typeData?.getCreationTypes ? typeData.getCreationTypes : []}
                getOptionLabel={(option) => t(option.name)}
                getOptionValue={(option) => option.id}
                onChange={(option) => setItem({ ...item, type: option.id })}
                error={errors.type}
            />
            {
                typeData?.getCreationTypes
                    && typeData.getCreationTypes.length > 0
                    && item.type === typeData.getCreationTypes.find(x => x.name === CreationTypes.Series)?.id ?
                    <div className='modal-creation--series'>
                        <Input
                            label={t('creations.season')}
                            value={item.season}
                            onChange={(e) => setItem({ ...item, season: e.target.value })}
                            type={'number'}
                        />
                        <Input
                            label={t('creations.episode')}
                            value={item.episode}
                            onChange={(e) => setItem({ ...item, episode: e.target.value })}
                            type={'number'}
                            error={errors.episode}
                        />
                        <Input
                            label={t('creations.episode_title')}
                            value={item.episodeTitle}
                            onChange={(e) => setItem({ ...item, episodeTitle: e.target.value })}
                        />
                    </div>
                    :
                    <></>
            }
            <DurationInput
                label={t('creations.duration')}
                onChange={(seconds) => setDuration(seconds)}
                error={errors.duration}
            />
            <Input
                label={t('creations.country')}
                value={item.country}
                onChange={(e) => setItem({ ...item, country: e.target.value })}
            />
            <Input
                label={t('creations.producer')}
                value={item.producer}
                onChange={(e) => setItem({ ...item, producer: e.target.value })}
            />

            {
                authors?.length ?
                    <div className='creation-authors'>
                        {
                            authors.map((author, index) =>
                                <div className='creation-authors--item-info' key={`modal-author-${index}`}>
                                    <div className='creation-authors--item-name'>
                                        {capitalizeName(author.authorName)}
                                    </div>
                                    {
                                        author.authorRole && roleData?.getAuthorRoles?.length ?
                                            <div className='creation-authors--item-role'>
                                                { t(roleData.getAuthorRoles?.find(i => i.id === author.authorRole)?.name) }
                                            </div>
                                            :
                                            <></>
                                    }
                                    <div className='creation-authors--item-meta'>
                                        <Input
                                            label={t('IPI NN')}
                                            type={'number'}
                                            value={author.ipi}
                                            disabled
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <></>
            }

            {
                errors.generic ?
                    <div className='error-message'>
                        <span>{errors.generic}</span>
                    </div>
                    :
                    <></>
            }

            <Button
                label={t('creations.add_new')}
                onClick={handleAdd}
            />
        </Modal>
    )
}

export default AddCreationModal