import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GetDistributions, GetDistributionTotal, GetReport, GetViewFilters } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import { ReportTypes } from '../../util/const'
import { formatDate } from '../../util/format'
import { capitalizeName } from '../../util/string'
import DistributionFilters from '../../components/DistributionFilters'
import Total from '../../components/Total'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'authorName',
        label: 'Autor',
    },
    {
        value: 'points',
        label: 'Punktid',
    },
    {
        value: 'total',
        label: 'Bruto summa',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const DistributionScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id, name } = useParams()
    const tableRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [total, setTotal] = useState(null)

    const { refetch: getTotal } = useQuery(GetDistributionTotal, {
        skip: true,
    })

    const { data: reportData } = useQuery(GetReport, { variables: { id: parseInt(id) } })

    useEffect(() => {
        (async () => {
            if (reportData?.getReport?.type && reportData?.getReport?.year) {
                const res = await getTotal({
                    year: parseInt(reportData.getReport.year),
                    type: reportData.getReport.type,
                    distribution: name,
                })

                if (res?.data?.getDistributionTotal) setTotal(res?.data?.getDistributionTotal)
            }
        })()
    }, [reportData])

    const handleRowClick = (row) => {
        if (!row?.authorName) return

        navigate(`/distribution/${id}/${name}/${row.authorName}`)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'total':
                return `${(parseFloat(row[field])).toFixed(2)} €`
            case 'createdAt':
                return formatDate(row[field])
            case 'year':
                return row.report?.year
            case 'authorName':
                return capitalizeName(row[field])
            case 'points':
                return parseFloat(row[field]).toFixed()
            default:
                return row[field]
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const getRowClass = (row) => {
        return row?.user?.id || row?.namedUser?.id ? 'is-user' : ''
    }

    return (
        <div className='distributions'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('distributions.title') }</h1>
                    <h4>{ `${name} - ${reportData?.getReport?.type} ${reportData?.getReport?.year}` }</h4>
                </div>
                <div className='below-title'>
                    <DistributionFilters
                        onFilterChange={handleFilterChange}
                    />
                    <Total
                        total={total}
                    />
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetDistributions}
                        fieldConditions={fieldConditions}
                        queryKey={'getDistributions'}
                        hideInputs={true}
                        initialOrderBy={'authorName'}
                        initialSort={'ASC'}
                        queryOptions={{
                            variables: {
                                reportId: parseInt(id),
                                name,
                            },
                        }}
                        onRowClick={handleRowClick}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        rowClass={getRowClass}
                    />
                </div>
            </div>
        </div>
    )
}

export default DistributionScreen