import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetPayouts } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import PayoutFilters from '../../components/PayoutFilters'
import ImportIcon from '../../components/icons/import'
import DeleteIcon from '../../components/icons/delete'
import { RemoveForeign, RemovePayout } from '../../graphql/mutations'
import Tasks from '../../components/Tasks'
import { TaskTypes } from '../../util/const'
import AddForeignModal from '../../components/AddForeignModal'
import ExportForeignModal from '../../components/ExportForeignModal'
import ImportPayoutsModal from '../../components/ImportPayoutsModal'
import { formatDate } from '../../util/format'
import IncomeTaxModal from '../../components/IncomeTaxModal'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'pid',
        label: 'Isikukood',
    },
    {
        value: 'type',
        label: 'Tulu liik',
    },
    {
        value: 'society',
        label: 'Ühing',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'sum',
        label: 'Summa',
    },
    {
        value: 'fee',
        label: 'Teenustasud',
    },
    {
        value: 'tax',
        label: 'Tulumaks',
    },
    {
        value: 'total',
        label: 'Väljamakse',
    },
    {
        value: 'date',
        label: 'Kuupäev',
    },
]

const PayoutsScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [removePayout] = useMutation(RemovePayout)
    const [selectedRows, setSelectedRows] = useState([])
    const [showIncomeTaxModal, setShowIncomeTaxModal] = useState(false)

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const fieldConditions = (row, field) => {
        if (['sum', 'total', 'tax', 'fee'].includes(field)) return row[field] ? `${row[field]} ${row.currency}` : ''
        if (field === 'date') return formatDate(row[field])
        return row[field]
    }

    const handleSuccess = () => {
        setShowAddModal(false)
        tableRef.current?.refresh()
        filtersRef.current?.refresh()
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleImportSuccess = () => {
        setShowImportModal(false)
        tableRef.current?.refresh()
    }

    const handleDelete = async () => {
        try {
            const res = await removePayout({
                variables: {
                    ids: selectedRows.map(x => x.id),
                },
            })

            if (res?.data?.removePayout === 'SUCCESS') {
                tableRef.current?.refresh()
                setSelectedRows([])
            }
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    return (
        <div className='foreign'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('payouts.title') }</h1>
                    <Button
                        label={ t('payouts.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                    {
                        selectedRows.length > 0 ?
                        <Button
                            label={ t('payouts.delete') }
                            onClick={handleDelete}
                            icon={<DeleteIcon />}
                        />
                        :
                        <></>
                    }
                    <Button
                        label={t('users.create_income_tax_statement')}
                        onClick={() => setShowIncomeTaxModal(true)}
                    />
                </div>

                <Tasks
                    type={TaskTypes.PayoutImport}
                />

                <div className='below-title'>
                    <PayoutFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allow={{
                            year: true,
                            search: true,
                        }}
                    />
                </div>

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetPayouts}
                        queryKey={'getPayouts'}
                        hideInputs={true}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'name'}
                        onRowClick={handleRowClick}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        activeRows={selectedRows}
                        allowSelect={true}
                    />
                </div>
            </div>
            <AddForeignModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
            <ImportPayoutsModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
            />
            <ExportForeignModal
                show={exportModal}
                close={() => setExportModal(false)}
            />
            <IncomeTaxModal
                show={showIncomeTaxModal}
                close={() => setShowIncomeTaxModal(false)}
            />
        </div>
    )
}

export default PayoutsScreen