import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetFixRequests } from '../../graphql/queries'
import { useMutation } from '@apollo/client'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import Fix from '../../components/Fix'
import { formatDate } from '../../util/format'
import { FixType } from '../../util/const'
import { ApproveFixRequest, RemoveFixRequest } from '../../graphql/mutations'
import { useNavigate } from 'react-router-dom'
import AddCreationModal from '../../components/AddCreationModal'

const includeFields = [
    {
        value: 'type',
        label: 'Tüüp',
    },
    {
        value: 'request',
        label: 'Muudatus',
    },
    {
        value: 'user',
        label: 'Kasutaja',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
    {
        value: 'action',
        label: '',
    },
]

const FixesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const navigate = useNavigate()
    const [creationData, setCreationData] = useState()
    const [removeFix] = useMutation(RemoveFixRequest)
    const [approveFix] = useMutation(ApproveFixRequest)

    const handleRemove = async (id) => {
        const res = await removeFix({ variables: { id } })

        if (res.data?.removeFixRequest === 'SUCCESS') {
            tableRef.current.refresh()
        }
    }

    const handleApprove = async (row) => {
        if (!row?.id) return

        const res = await approveFix({ variables: { id: row.id } })

        if (res.data?.approveFixRequest === 'SUCCESS') {
            if (row?.request?.creationId) navigate(`/creation/${row.request.creationId}`)
            tableRef.current.refresh()
        }
    }

    const handleAddSuccess = async (creationId) => {
        if (!creationId) return

        if (creationData?.fixId) {
            await handleRemove(parseInt(creationData.fixId))
        }
        navigate(`/creation/${creationId}`)
    }

    const fieldConditions = (row, field) => {
        if (field === 'user' && row[field]?.name) return row[field].name
        if (field === 'request') return <Fix id={row.id} data={row[field]} type={row.type} user={row.user} onAddCreation={(data) => setCreationData(data)} />
        if (field === 'type') return t(`fixes.${row.type}`)
        if (field === 'createdAt') return formatDate(row[field])
        if (field === 'action') return (
            <div className='fix-actions'>
                {
                    row.type !== FixType.AddCreation ?
                        <Button
                            label={'Kinnita'}
                            onClick={() => handleApprove(row)}
                        />
                        :
                        <></>
                }
                <Button
                    label={'Kustuta'}
                    onClick={() => handleRemove(row.id)}
                />
            </div>
        )
        return row[field]
    }

    return (
        <div className='fixes'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{t('fixes.title')}</h1>
                </div>

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetFixRequests}
                        queryKey={'getFixRequests'}
                        fieldConditions={fieldConditions}
                        hideInputs={true}
                        includeFields={includeFields}
                        initialOrderBy={'createdAt'}
                        initialSort={'DESC'}
                    />
                </div>
            </div>
            <AddCreationModal
                show={!!creationData}
                close={() => setCreationData(null)}
                onSuccess={handleAddSuccess}
                initialData={creationData?.creation}
                authors={creationData?.authors}
            />
        </div>
    )
}

export default FixesScreen