import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../components/Checkbox'
import { useMutation, useQuery } from '@apollo/client'
import { GetProfile } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { EditProfile } from '../../graphql/mutations'
import HeirItem from '../../components/HeirItem'
import AuthorItem from '../../components/AuthorItem'

const initialState = {
    name: null,
    pid: null,
    ipi: null,
    email: null,
    phone: null,
    address: null,
    iban: null,
    pseudonym: null,
    role: null,
}

const ProfileScreen = () => {

    const { t } = useTranslation()
    const [edit] = useMutation(EditProfile)
    const [item, setItem] = useState(initialState)

    const { loading } = useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile) return
            setItem(data.getProfile)
        },
    })

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        let finalValue = value

        updateProperty(field, finalValue)
    }

    const updateProperty = async (field, value) => {
        try {
            await edit({
                variables: {
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty: ', err)
        }
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='profile'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ item.name }</h1>
                </div>
                <div className='profile-content'>
                    <div className='profile-info'>
                        <Input
                            disabled={true}
                            label={ t('user.pid') }
                            value={item.pid}
                            onChange={(e) => setField('pid', e.target.value)}
                        />
                        <Input
                            disabled={true}
                            label={ t('user.ipi') }
                            value={item.ipi}
                            onChange={(e) => setField('ipi', e.target.value)}
                        />
                        <Input
                            disabled={true}
                            label={ t('user.email') }
                            value={item.email}
                            onChange={(e) => setField('email', e.target.value)}
                        />
                        <Input
                            label={ t('user.phone') }
                            value={item.phone}
                            onChange={(e) => setField('phone', e.target.value)}
                        />
                        <Input
                            label={ t('user.address') }
                            value={item.address}
                            onChange={(e) => setField('address', e.target.value)}
                        />
                        <Input
                            label={ t('user.iban') }
                            value={item.iban}
                            onChange={(e) => setField('iban', e.target.value)}
                        />
                    </div>
                
                    <div className='profile-inheritance'>
                        {
                            item.heirs && item.heirs.length > 0 ?
                            <div className='profile-heirs'>
                                <h3>{ t('user.heirs') }</h3>
                                <div className='profile-heirs--list'>
                                    {
                                        item.heirs.map((heirItem) =>
                                            <HeirItem
                                                key={heirItem.id}
                                                data={heirItem}
                                                editDisabled={true}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            :
                            <></>
                        }

                        {
                            item.authors && item.authors.length > 0 ?
                            <div className='profile-authors'>
                                <h3>{ t('user.authors') }</h3>
                                <div className='profile-authors--list'>
                                    {
                                        item.authors.map((authorItem) =>
                                            <AuthorItem
                                                key={authorItem.id}
                                                data={authorItem}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileScreen