import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import FileUpload from './FileUpload'
import Modal from './Modal'
import { ImportPayouts } from '../graphql/mutations'
import DateInput from './DateInput'
import { parseDate } from '../util/format'

const ImportPayoutsModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [error, setError] = useState()
    const [date, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const [importPayouts] = useMutation(ImportPayouts)

    const handleImport = async (file) => {
        setLoading(false)
        
        try {
            const res = await importPayouts({
                variables: {
                    file,
                    date,
                },
            })

            if (res?.data?.importPayouts?.code === 'SUCCESS') {
                if (onSuccess) onSuccess()
                return
            }

            if (res?.data?.importPayouts?.items?.length > 0) {
                const failList = res.data.importPayouts.items.map(x => `(${x.error}) Name: ${x.name}, Total: ${x.total}, PID: ${x.pid}`)
                setError(`${t('payouts.import_error')}\n\n${failList.join('\n\n')}`)
                return
            }

            setError(t('payouts.import_error'))
        } catch (err) {
            setError(t('payouts.import_error'))
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setError(null)
    }

    return (
        <Modal
            className={'modal-payouts'}
            show={show}
            close={handleClose}
            title={ t('payouts.import') }
        >
            <DateInput
                label={t('payouts.date')}
                value={date ? new Date(date) : null}
                onChange={(val) => setDate(parseDate(val))}
            />

            <p className='date-description'>{ t('payouts.date_description') }</p>

            <FileUpload
                handleUpload={handleImport}
                loading={loading}
            />

            {
                error ?
                <div className='error-message'>
                    <span>{ error }</span>
                </div>
                :
                <></>
            }
        </Modal>
    )
}

export default ImportPayoutsModal