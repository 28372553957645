import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { AddUser } from '../graphql/mutations'
import { UserRoles } from '../util/const'
import SelectInput from './SelectInput'

const initialState = {
    name: '',
    pid: '',
    email: '',
    phone: '',
    ipi: '',
    role: '',
    address: '',
    iban: '',
}

const initialErrorsState = {
    name: null,
    pid: null,
    email: null,
    phone: null,
    ipi: null,
    role: null,
    address: null,
    iban: null,
    generic: null,
}

const userRoles = [
    {
        label: 'Administraator',
        value: UserRoles.Admin,
    },
    {
        label: 'Klient',
        value: UserRoles.Client,
    },
    {
        label: 'Liige',
        value: UserRoles.Member,
    },
]

const AddUserModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [add] = useMutation(AddUser)

    const handleAdd = async () => {
        if (hasInputErrors()) return
        try {
            const res = await add({
                variables: {
                    data: item,
                },
            })

            if (!res?.data?.addUser || res.data.addUser === 'FAIL') {
                setErrors({
                    ...initialErrorsState,
                    generic: t('users.add_error'),
                })
                return
            }

            if (res.data.addSociety === 'EXISTS') {
                setErrors({
                    ...initialErrorsState,
                    generic: t('users.existing_error'),
                })
                return
            }

            if (onSuccess) onSuccess(res.data.addUser)
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('users.add_error'),
            })
            console.log(err)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.name) {
            hasErrors = true
            errorState.name = t('reports.fill_field')
        }

        if (!item.email) {
            hasErrors = true
            errorState.email = t('reports.fill_field')
        }

        if (!item.role) {
            hasErrors = true
            errorState.role = t('reports.fill_field')
        }

        if (!item.pid) {
            hasErrors = true
            errorState.pid = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    return (
        <Modal
            className={'modal-user'}
            show={show}
            close={handleClose}
            title={ t('users.add_title') }
        >
            <Input
                label={ t('users.name') }
                placeholder={ t('users.last_first_name') }
                value={item.name}
                onChange={(e) => setField('name', e.target.value)}
                error={errors.name}
            />
            <SelectInput
                label={ t('users.role') }
                options={userRoles}
                value={userRoles.find(x => x.value === item.role)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setField('role', option.value)}
                error={errors.role}
            />
            <Input
                label={ t('users.pid') }
                value={item.pid}
                type={'number'}
                onChange={(e) => setField('pid', e.target.value)}
                error={errors.pid}
            />
            <Input
                label={ t('users.email') }
                value={item.email}
                onChange={(e) => setField('email', e.target.value)}
                error={errors.email}
            />
            <Input
                label={ t('users.phone') }
                value={item.phone}
                onChange={(e) => setField('phone', e.target.value)}
            />
            <Input
                label={ t('users.address') }
                value={item.address}
                onChange={(e) => setField('address', e.target.value)}
            />
            <Input
                label={ t('users.iban') }
                value={item.iban}
                onChange={(e) => setField('iban', e.target.value)}
            />

            {
                errors?.generic ?
                <div className='error-message'>
                    <span>{ errors.generic }</span>
                </div>
                :
                <></>
            }

            <Button
                label={ t('users.add_new') }
                onClick={handleAdd}
            />
        </Modal>
    )
}

export default AddUserModal