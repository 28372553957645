import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectInput from './SelectInput'

const MinYear = 2021

const initialState = {
    year: null,
}

const initialOptions = {
    years: [],
}

const UserBalanceFilters = forwardRef(({
    onFilterChange,
}, ref) => {

    const { t } = useTranslation()
    const [filterOptions, setFilterOptions] = useState(initialOptions)
    const [filters, setFilters] = useState(initialState)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)

        const years = []

        for (let i = MinYear; i <= new Date().getFullYear(); i++) {
            years.push({
                label: i,
                value: i,
            })
        }

        setFilterOptions({
            ...filterOptions,
            years,
        })

        setFilters({
            ...filters,
            year: years?.length ? years[years.length - 1].value : null,
        })
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters filters-balance'>
            <SelectInput
                options={filterOptions.years}
                onChange={(option) => setField('year', option ? parseInt(option.value) : null)}
                value={filterOptions.years.find(x => parseInt(x.value) === filters.year)}
                placeholder={t('filters.year')}
                label={t('filters.year')}
            />
        </div>
    )
})

export default UserBalanceFilters